import type { InputProps } from '@chakra-ui/react';
import { Box } from '@playful/design_system';
import React from 'react';

import { SlugEditor } from '../components/SlugEditor';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsFormLabel } from './ProjectSettingsLayout';

export function ChooseSlug(props: { label?: string } & Omit<InputProps, 'onChange'>) {
  const {
    slug,
    currentDomain,
    updatedDomain,
    slugErrMsg,
    isLoading,
    onSlugChange,
    slugInputValue,
    setSlugInputValue,
    updatedSlug,
  } = useProjectSettingsContext();
  const { label, ...inputProps } = props;

  const slugHintText = slugErrMsg || `${updatedDomain}/${updatedSlug || slug}`;

  return (
    <Box my={4}>
      <ProjectSettingsFormLabel htmlFor={'project-url'} mb={1}>
        {label || currentDomain}
      </ProjectSettingsFormLabel>
      <SlugEditor
        id='project-url'
        slug={slug}
        isDisabled={isLoading}
        inputValue={slugInputValue}
        setInputValue={setSlugInputValue}
        onChange={onSlugChange}
        placeholder={'add project name'}
        status={!!slugErrMsg ? 'error' : 'initial'}
        slugHint={<>{slugHintText}</>}
        autoComplete={'off'}
        {...inputProps}
      />
    </Box>
  );
}
