import { UseToastOptions, createStandaloneToast, theme, useToast } from '@chakra-ui/react';
import type { ToastPosition } from '@chakra-ui/toast';
import { Box } from '@playful/design_system';
import { generateUUID } from '@playful/utils';
import React, { useEffect } from 'react';
import { proxy, ref, useSnapshot } from 'valtio';

const { toast, ToastContainer } = createStandaloneToast();

export type AppToastStatus = 'info' | 'warning' | 'success' | 'error';

export type AppToast = {
  id: string;
  description?: JSX.Element | string;
  title?: JSX.Element | string;
  status?: AppToastStatus;
  position?: ToastPosition;
  // If no duration supplied, defaults to 5000ms; null duration means toast stays open until dismissed by calling removeToast(id)
  duration?: number | null;
};

export type AppToastOptions = Pick<AppToast, 'position' | 'duration' | 'title'>;

const initialToastState = {
  toasts: [] as AppToast[],
};

export const toastState = proxy(initialToastState);

export const addToast = (toast: AppToast) => {
  const { id, status = 'info', title, description, position = 'bottom', duration } = toast;

  const exists = toastState.toasts.find((t: AppToast) => t.id === id);
  if (exists) return;

  toastState.toasts.push(
    // Use Valtio's ref() to prevent messages that are ReactNodes from being proxy-wrapped
    // which seems to confuse React downstream.
    ref({
      id,
      title,
      description,
      status,
      position,
      duration,
    }),
  );
};

export const addErrorToast = (message?: React.ReactNode, options?: AppToastOptions) => {
  const t: AppToast = {
    id: generateUUID(),
    description: typeof message !== 'string' ? JSON.stringify(message) : message,
    status: 'error',
    ...options,
  };

  addToast(t);
};

export const addSuccessToast = (message?: JSX.Element | string, options?: AppToastOptions) => {
  const t: AppToast = {
    id: generateUUID(),
    description: message,
    status: 'success',
    ...options,
  };

  addToast(t);
};

export const addInfoToast = (message?: string, options?: AppToastOptions) => {
  const t: AppToast = {
    id: generateUUID(),
    description: message,
    status: 'info',
    ...options,
  };

  addToast(t);
};

export const removeToast = (toastId: string) => {
  toastState.toasts = toastState.toasts.filter((t: AppToast) => t.id !== toastId);
  toast.close(toastId);
};

export default function AppToasts() {
  const { toasts } = useSnapshot(toastState);
  const toast = useToast();
  useEffect(() => {
    toasts.map((t) => {
      const customToast = () => {
        return (
          <Box
            backgroundColor={'gray.500'}
            color={'white'}
            fontSize={'xs'}
            fontWeight={'bold'}
            borderRadius={'full'}
            px={3}
            py={2}
            marginBottom={8}
            textAlign={'center'}
            boxShadow={'md'}
          >
            {t.title}
            {t.description}
          </Box>
        );
      };

      const toastOptions: UseToastOptions = {
        id: t.id,
        status: t.status,
        title: t.title,
        description: t.description,
        duration: t.duration,
        position: t.position,
        variant: 'left-accent',
        isClosable: true,
        containerStyle: {
          minWidth: theme.sizes[48],
        },
        onCloseComplete: () => {
          removeToast(t.id);
        },
      };

      // If t.status is not provided, default to the gray custom toast
      if (t.status === undefined || t.status === 'info') {
        toastOptions.render = customToast;
      }

      if (!toast.isActive(t.id)) {
        toast(toastOptions);
      }
    });
  }, [toast, toasts, toasts.length]);

  return <ToastContainer />;
}
