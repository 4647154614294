import { Box, HStack, ProBadgeIcon, Switch, Tooltip } from '@playful/design_system';
import { subscriptionPaywalls } from '@playful/workbench/SubscriptionPaywalls';
import React from 'react';

import { useProjectPermissions } from '../hooks/useProject';
import { PasswordProtectSetting } from './PasswordProtectSetting';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { SwitchField } from './SwitchField';
import { SwitchLabel } from './SwitchLabel';

export function ProjectPermissions() {
  const { isLoading, onError, projectInfo } = useProjectSettingsContext();

  const { setShared, disableShare, setRemixable, setBranding, hasBranding, isShared, isRemixable } =
    useProjectPermissions({
      id: projectInfo.id,
      onSetShared: (err, isShared) =>
        err && onError?.(`your project couldn't be ${isShared ? 'shared' : 'unshared'}`),
      onSetRemixable: (err, isRemixable) =>
        err &&
        onError?.(`your project couldn't be set to ${isRemixable ? 'remixable' : 'unremixable'}`),
      onSetBranding: (err) => err && onError?.(`your project's branding couldn't be set.`),
    });

  function handleHatchLogoChange() {
    if (subscriptionPaywalls.removeHatchLogo()) {
      setBranding?.(!hasBranding);
    } else {
      subscriptionPaywalls.openModal({
        reason: 'removewatermark',
        returnTo: ['project-settings-popover'],
      });
    }
  }

  return (
    <>
      <SwitchField isDisabled={disableShare}>
        <Box pr={4}>
          <SwitchLabel
            htmlFor='share-on-hatch'
            tooltipLabel={'Visible on the public Hatch gallery and your profile page'}
          >
            share with the Hatch community
          </SwitchLabel>
        </Box>
        <Switch
          id='share-on-hatch'
          size='md'
          isDisabled={isLoading || disableShare}
          onChange={() => setShared?.(!isShared)}
          isChecked={isShared}
        />
      </SwitchField>
      <SwitchField>
        <Box pr={4}>
          <SwitchLabel
            htmlFor='allow-remixing'
            tooltipLabel={
              'Allows anyone to create a copy of your project and remix it as their own'
            }
          >
            allow remixing
          </SwitchLabel>
        </Box>
        <Switch
          id='allow-remixing'
          size='md'
          isDisabled={isLoading}
          onChange={() => setRemixable?.(!isRemixable)}
          isChecked={isRemixable}
        />
      </SwitchField>
      <SwitchField>
        <Box pr={4}>
          <SwitchLabel
            htmlFor='made-with-play'
            tooltipLabel={
              'Spread awareness of the Hatch community by including a link back to Hatch on your published site'
            }
          >
            remove hatch watermark
          </SwitchLabel>
        </Box>
        <HStack spacing={2}>
          {!subscriptionPaywalls.removeHatchLogo() && (
            <Tooltip
              hasArrow
              offset={[0, 12]}
              placement='top'
              label='Upgrade to Pro to remove Hatch watermark.'
            >
              <Box as='span' cursor='help'>
                <ProBadgeIcon h={5} w={5} data-testid={'watermark-pro-badge'} />
              </Box>
            </Tooltip>
          )}
          <Switch
            aria-label='made with play link'
            id='made-with-play'
            size='md'
            isDisabled={isLoading}
            onChange={handleHatchLogoChange}
            isChecked={!hasBranding}
          />
        </HStack>
      </SwitchField>

      <PasswordProtectSetting />
    </>
  );
}
