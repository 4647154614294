import {
  Box,
  BoxProps,
  Button,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Text,
  theme,
} from '@chakra-ui/react';
import { FileUploadIcon, TrashIcon } from '@playful/design_system';
import { Content, playContentMimeType } from '@playful/runtime';
import { withF, generateUUID } from '@playful/utils';
import { fetchUrlData } from '@playful/workbench/project/resources';
import React, {
  DetailedHTMLProps,
  InputHTMLAttributes,
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useColor } from '../hooks/useColor';
import { DragDetails, useDragAndDrop, useDragAndDropProps } from '../hooks/useDragAndDrop';

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

type FileInputProps = Omit<InputProps, 'onDrop'> &
  Pick<useDragAndDropProps, 'allowedTypes'> & {
    onAdd?: (file: File) => void;
    innerButton?: () => ReactNode;
    allowedInputFileTypes: string;
    file?: File | string | Blob | null;
    onReset?: () => void;
    hint?: string;
    inputId?: string;
    isLoading?: boolean;
    onOpen?: () => void;
    BoxProps?: BoxProps;
  };

export const createFilePreview = (_file: FileInputProps['file']) => {
  const isStrFilePath = _file && typeof _file === 'string';

  return isStrFilePath ? _file : _file && URL.createObjectURL(_file as Blob);
};

export function FileInput({
  innerButton,
  disabled,
  allowedInputFileTypes,
  allowedTypes,
  onAdd,
  onReset,
  file,
  hint,
  inputId = 'upload-component-thumbnail',
  isLoading,
  onOpen,
  BoxProps = {},
}: FileInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const preview = createFilePreview(file);
  const [overDrop, setOverDrop] = useState(false);

  const { imgRef, onLoad } = useColor({ step: 16 });

  // because we can't set the state of a file input,
  // use it only to accessibly select a file, but otherwise keep the value clear
  // so it doesn't have the wrong file attached vs what was potentially dragged
  // or passed in.
  useEffect(() => {
    if (inputRef.current) inputRef.current.value = '';
  }, [file]);

  function reset(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    e.stopPropagation();

    onReset?.();
  }

  function handleSelection(file?: File | null) {
    if (!file) return;

    onAdd?.(file);
  }

  const handleDrop = (event: DragEvent, options: DragDetails) => {
    const [droppedFile] = event.dataTransfer?.files || [];

    if (droppedFile) {
      if (!options.canDrop) return;

      setOverDrop(false);
      handleSelection(droppedFile);
      return;
    }

    const contentJson = event.dataTransfer?.getData(playContentMimeType);
    if (!droppedFile && contentJson) {
      const content: Content = JSON.parse(contentJson);
      if (
        (content && content.type === 'image') ||
        content.type === 'video' ||
        content.type === 'audio'
      ) {
        fetchUrlData(content.url).then((blob) => {
          const file = new File([blob], content?.name || generateUUID(), { type: blob.type });
          handleSelection(file);
        });
        setOverDrop(false);
      }
    }
  };

  const [dropRef] = useDragAndDrop<HTMLDivElement>({
    disabled,
    allowedTypes,
    onDrop: handleDrop,
    onDragIn: (_, options) => setOverDrop(options.canDrop),
    onDragOut: withF(setOverDrop),
    dragEffect: 'move',
  });

  // Overall dimensions for File Upload
  const HEIGHT = '100px';
  const WIDTH = '192px';

  return (
    <>
      <Box
        ref={dropRef}
        border={`1px ${overDrop ? theme.colors.gray['400'] : theme.colors.gray['200']} dashed`}
        borderRadius={theme.radii['lg']}
        boxSizing={'content-box'}
        position={'relative'}
        backgroundColor={'gray.50'}
        _hover={{
          backgroundColor: 'gray.100',
        }}
        color={'gray.700'}
        maxWidth={'192px'}
        maxHeight={'100px'}
        {...BoxProps}
      >
        {preview && !disabled && (
          <IconButton
            size='xs'
            aria-label='Remove'
            onClick={reset}
            position={'absolute'}
            top={preview ? '.25rem' : '-.5rem'}
            right={preview ? '.25rem' : '-.5rem'}
            backgroundColor={'white'}
            border={`1px ${theme.colors.gray['200']} solid`}
            zIndex={3}
            _focus={{
              outline: '1px black solid',
            }}
          >
            <TrashIcon />
          </IconButton>
        )}
        <Box position={'relative'} filter={overDrop ? 'blur(3px)' : 'none'}>
          <Input
            ref={inputRef}
            isDisabled={disabled}
            {...(allowedInputFileTypes && { accept: allowedInputFileTypes })}
            type='file'
            id={inputId}
            onChange={(e) => handleSelection(e.target?.files?.[0])}
            position={'absolute'}
            left={0}
            top={0}
            // For cursor: pointer to work over the FileInput, we must hide the default file input button
            width={'0'}
            height={'0'}
            opacity={0}
            aria-hidden='true'
            onClick={onOpen}
            border={0}
          />
          <FormLabel
            htmlFor={inputId}
            w={'100%'}
            display={'flex'}
            justifyContent={'center'}
            cursor='pointer'
            width={WIDTH}
            height={HEIGHT}
            overflow={'hidden'}
            mb={0}
            mr={0}
          >
            {/* Use a Button to take advantage of the built-in isLoading for a loading/uploading indicator */}
            <Button
              display={'flex'}
              justifyContent={'center'}
              isLoading={isLoading}
              whiteSpace={'normal'}
              background={'transparent'}
              pointerEvents={'none'}
              width={WIDTH}
              height={HEIGHT}
              cursor='pointer'
              p={0}
            >
              {preview ? (
                <img
                  onLoad={onLoad}
                  ref={imgRef}
                  style={{
                    height: '100%',
                    objectFit: 'contain',
                  }}
                  src={preview}
                />
              ) : (
                <Stack
                  display='flex'
                  justifyContent={'center'}
                  alignContent={'center'}
                  textAlign={'center'}
                  overflow={'visible'}
                  gap={0}
                >
                  <FileUploadIcon w={6} h={6} alignSelf={'center'} />
                  <Text fontSize={'12px'} fontWeight={500} whiteSpace={'nowrap'}>
                    upload image
                  </Text>
                </Stack>
              )}
            </Button>
          </FormLabel>
        </Box>
      </Box>
      {!disabled && hint && (
        <Text width={'140px'} display={'block'}>
          {hint}
        </Text>
      )}
    </>
  );
}
