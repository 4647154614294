import type { CustomDomain } from '@playful/api';
import type { ProjectInfo, ProjectPageMetadata } from '@playful/runtime';
import { createGenericContext } from '@playful/utils';
import type { Resource } from '@playful/workbench/project/resources';
import React from 'react';

export type ProjectSettingsContext = {
  projectInfo: ProjectInfo;
  isLoading: boolean; //

  onOpen?: () => void;
  onClose?: () => void;
  onError?: (msg?: string | undefined) => void;
  onSuccess?: (msg?: string | undefined) => void;

  isLinkCopied: boolean; // is true for x seconds after clicking copy link. controls "link copied!"
  isEmbedCopied: boolean; // is true for x seconds after clicking copy embed. controls "embed copied!"
  onLinkCopy: () => void; // handler for copy link button
  onCopyEmbed: () => void; // handler for copy embed button
  isLoadingEmbed: boolean; // is true while embed code is being fetched
  onSocialShare: (type: 'fb' | 'tw') => void; // handler for social share buttons
  onNativeShare: () => void; // handler for native share button
  isNativeShare: boolean; // is true if native share is supported
  isPublished: boolean; // is true if project is published
  projectResource: Resource; // project resource
  customDomains: CustomDomain[]; // SWR updated array of custom domains for the current user
  onPublished: () => void; // handler for publish/unpublish button
  canPublish: boolean; // are there any validation errors preventing publish
  currentDomain: string; // current domain stored in the db
  isPublishedDomainCustom: boolean; // is the current domain a custom domain
  isUpdatedDomainCustom: boolean; // is the updated domain (domain chosen but not saved) a custom domain
  isSlugInputDirty: boolean;
  onSlugChange: (slug: string) => void;
  updatedSlug: string | undefined; // slug chosen but not saved
  slugErrMsg: string | boolean;
  slug: string | undefined; // slug stored in the db
  onDomainChange: (domain: string) => void;
  updatedDomain: string; // domain chosen but not saved
  path: string; // path chosen but not saved, slugified
  pathInputValue: string; // path chosen but not saved, not slugified
  slugInputValue: string; // slug chosen but not saved, not slugified
  setPathInputValue: (value: string) => void;
  setSlugInputValue: (value: string) => void;
  onPathChange: React.Dispatch<React.SetStateAction<string>>;
  pathInvalid: boolean;
  pathErrMsg: string;
  domainErrMsg: string;
  publicUrl: string; // the current, saved in db, public url
  isEditingPublicUrl: boolean;
  setIsEditingPublicUrl: (isEditing: boolean) => void;
  setIsDomainModalOpen: (isOpen: boolean) => void;
  googleAnalyticsIdInputValue: string;
  onGoogleAnalyticsIdInputValue: React.Dispatch<React.SetStateAction<string>>;
  googleAnalyticsErrMsg: string;
  pageMetadataInput?: ProjectPageMetadata;
  onPageMetadataInputChange: React.Dispatch<React.SetStateAction<ProjectPageMetadata | undefined>>;
  // Employee only
  customHeaderContentInputValue: string;
  onCustomHeaderContentInputValue: React.Dispatch<React.SetStateAction<string>>;
};

const [hook, GenericProvider] = createGenericContext<ProjectSettingsContext>();

export const useProjectSettingsContext = hook;

export function ProjectSettingsProvider(
  props: ProjectSettingsContext & { children: React.ReactNode },
) {
  const { children, ...rest } = props;

  return <GenericProvider value={rest}>{children}</GenericProvider>;
}
