import { FormLabel, FormLabelProps } from '@chakra-ui/react';
import { Tooltip } from '@playful/design_system';
import React, { Fragment, useMemo } from 'react';

export function SwitchLabel({
  tooltipLabel,
  ...props
}: FormLabelProps & { tooltipLabel?: string }) {
  const Wrapper = useMemo(() => {
    return tooltipLabel
      ? ({ children }: { children: React.ReactNode }) => (
          <Tooltip label={tooltipLabel} hasArrow placement={'left'} offset={[0, 16]} padding={4}>
            {children}
          </Tooltip>
        )
      : Fragment;
  }, [tooltipLabel]);

  return (
    <Wrapper>
      <FormLabel fontSize='sm' mb={1} fontWeight={'normal'} cursor={'pointer'} {...props} />
    </Wrapper>
  );
}
