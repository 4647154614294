// Copy text to the clipboard using the old-school method

import type { ClipboardData, ClipboardItem } from '@playful/runtime';
import { fromPromise } from '@playful/utils';
import { Base64 } from 'js-base64';

//   from: https://github.com/sudodoki/copy-to-clipboard/blob/master/index.js
function copyTextDOMElement(
  textToCopy: string,
  action: 'cut' | 'copy' = 'copy',
  _el?: HTMLElement | null,
) {
  const el = _el || document.body;
  const mark = document.createElement('span');
  mark.textContent = textToCopy;

  // avoid screen readers from reading out loud the text
  mark.ariaHidden = 'true';
  // reset user styles for span element
  mark.style.all = 'unset';
  // prevents scrolling to the end of the page
  mark.style.position = 'fixed';
  mark.style.top = '0';
  mark.style.clip = 'rect(0, 0, 0, 0)';
  // used to preserve spaces and line breaks
  mark.style.whiteSpace = 'pre';
  // do not inherit user-select (it may be `none`)
  mark.style.userSelect = 'text';

  el.appendChild(mark);

  const range = document.createRange();
  range.selectNodeContents(mark);

  const selection = window.getSelection()!;
  selection.removeAllRanges();
  selection.addRange(range);

  const status = document.execCommand(action);

  el.removeChild(mark);

  if (status === false) throw new Error(`Can't use clipboard`);
}

async function writeClipboardText(action: 'cut' | 'copy', text: string, el?: HTMLElement | null) {
  if (navigator.clipboard?.writeText) {
    const [fallbackErr] = await fromPromise(navigator.clipboard.writeText(text));

    if (!fallbackErr) return;

    console.warn(fallbackErr);
  }

  // fallback: try using execCommand with our text
  copyTextDOMElement(text, action, el);
}

/**
 * A function to write async content to the clipboard.
 *
 * NOTE: DON'T use this AFTER awaiting a promise. it will fail in safari, as it
 * restricts writing to the clipboard to happening directly after a user action.
 * wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox
 *
 * a string fallback is a second parameter here, for browsers that don't support
 * navigator.clipboard and need to rely on document.execCommand('copy'), which
 * cannot happen after awaiting a promise (in safari).
 *
 * if document.execCommand is the only option (like in-app browsers), and a fallback is not
 * passed, it will throw an error rather than copy an empty string. that may be desireable in
 * some circumstances, if you would rather not copy an empty string to the clipboard and
 * communicate the failure back to the user. we can't make every browser happy...
 *
 * @param p - the promise to resolve to a value
 * @param fallback - the non-async fallback for when we can't use a promise at all
 */
export async function writeClipboardTextAsync(
  p: () => Promise<string>,
  fallback = '',
  el?: HTMLElement | null,
) {
  let err: Error | undefined = undefined;

  if (typeof ClipboardItem && navigator.clipboard?.write) {
    // safari explodes with write permission errors if there's an uncaught error in a promise,
    // regardless of whether we try and catch it at any level above the promise, OR if we return
    // anything other than text. The best we can do is return the fallback string, which will
    // unfortunately get written to the clipboard, and save the error for later, which we can
    // return after the clipboard promise resolves and log/notify the user. this is not great
    // as the fallback string could be empty, which means we end up writing an empty string to
    // the clipboard AND throw an error. but, that's safari for you.
    const clipboardItem = new ClipboardItem({
      'text/plain': p()
        .then((text) => new Blob([text], { type: 'text/plain' }))
        .catch((e) => {
          err = e;
          return new Blob([fallback], { type: 'text/plain' });
        }),
    });

    const [writeErr] = await fromPromise(navigator.clipboard.write([clipboardItem]));

    // check to make sure there wasn't a clipboard item error, or a write error
    if (!err && !writeErr) return;

    err = writeErr || err;
    console.warn(err);
  }

  // next best thing
  if (navigator.clipboard?.writeText) {
    const str = await p();
    const [writeTextErr] = await fromPromise(navigator.clipboard.writeText(str));

    if (!writeTextErr) return;

    err = writeTextErr;
    console.warn(err);
  }

  // unlike ClipboardItem, here we CAN throw an error rather than writing an empty string to
  // the clipboard
  if (!fallback) throw new Error(err?.toString() || `Async clipboard failed`);

  // in-app browsers, etc
  await writeClipboardText('copy', fallback, el);
}

export async function writeComponentStateToClipboard(text: string, el?: HTMLElement | null) {
  if (typeof ClipboardItem && navigator.clipboard?.write) {
    const [err] = await fromPromise(
      navigator.clipboard.write([
        new ClipboardItem({
          ['text/plain']: new Blob([text], { type: 'text/plain' }),
        }),
      ]),
    );

    if (!err) return;
    if (err?.name === 'NotAllowedError') throw new Error(err);
  }

  // fallback
  await writeClipboardText('copy', text, el);
}

export async function writeComponentToClipboard(
  action: 'copy' | 'cut',
  text: string,
  componentJSON: ClipboardData,
) {
  // Encode the component JSON and store in HTML component
  // if any selected components have HTML content, add it to that.
  const componentJSONEncoded = Base64.encode(text);
  const htmlStrings: string[] = [];
  const textStrings: string[] = [];

  componentJSON.clipboard.forEach((cb: ClipboardItem) => {
    if (cb.state.html) {
      htmlStrings.push(cb.state.html);
    }
    if (cb.state.text) {
      textStrings.push(cb.state.text);
    }
  });

  const htmlContent = `<span data-playcomponent="${componentJSONEncoded}"></span>${htmlStrings.join(
    '',
  )}`;
  const componentHTMLItem = {
    'text/html': new Blob([htmlContent], { type: 'text/html' }),
  };

  // If any selected components have text content, copy as plain text
  let componentTextItem;
  if (textStrings.length) {
    componentTextItem = {
      'text/plain': new Blob([textStrings.join('')], { type: 'text/plain' }),
    };
  }

  const selection = document.getSelection();

  if (typeof ClipboardItem && navigator.clipboard?.write) {
    const [err] = await fromPromise(
      navigator.clipboard.write([
        new ClipboardItem({
          ...componentHTMLItem,
          ...componentTextItem,
        }),
      ]),
    );

    if (!err) return action === 'cut' && selection?.deleteFromDocument();
    if (err?.name === 'NotAllowedError') throw new Error(err);
  }

  // fallback
  await writeClipboardText(action, text);
}

// TODO: merge with writeComponentToClipboard?
export async function writeEffectToClipboard(text: string, el?: HTMLElement | null) {
  if (typeof ClipboardItem && navigator.clipboard?.write) {
    const textItem = { ['text/plain']: new Blob([text], { type: 'text/plain' }) };
    const [err] = await fromPromise(
      navigator.clipboard.write([
        new ClipboardItem({
          ...textItem,
          'text/html': new Blob([text], { type: 'text/html' }),
        }),
      ]),
    );

    if (err?.name === 'NotAllowedError') throw new Error(err);
  }

  // fallback
  await writeClipboardText('copy', text, el);
}
