import { forwardRef, useTheme } from '@chakra-ui/react';
import { TinyColor } from '@ctrl/tinycolor';
import { Box, BoxProps, CheckmarkIcon } from '@playful/design_system';
import React from 'react';

export const MediaOverlay = forwardRef(
  ({ isSelected, children, ...props }: BoxProps & { isSelected?: boolean }, ref) => {
    const theme = useTheme();
    const hoverBg = new TinyColor(theme.colors.blue[500]).setAlpha(0.25);

    return (
      <Box
        ref={ref}
        pos='relative'
        role='group'
        borderRadius='4px'
        overflow='hidden'
        _after={{
          content: '""',
          borderColor: 'blue.500',
          borderWidth: '3px',
          borderRadius: '4px',
          borderStyle: isSelected ? 'solid' : 'none',
          pos: 'absolute',
          w: '100%',
          h: '100%',
          pointerEvents: 'none',
          transition: 'all 0.1s',
          top: 0,
          left: 0,
          boxSizing: 'border-box',
        }}
        _hover={
          isSelected
            ? {
                _after: {
                  bg: hoverBg.toRgbString(),
                },
              }
            : undefined
        }
        {...props}
      >
        {children}
        <Box
          as='span'
          color={isSelected ? 'white' : 'gray.300'}
          bg={isSelected ? 'blue.500' : 'gray.100'}
          pos='absolute'
          top='8px'
          left='8px'
          borderRadius='2px'
          display='flex'
          opacity={isSelected ? 1 : 0}
          alignItems='center'
          justifyContent='center'
          transition='color 0.2s, background-color 0.2s'
          width='24px'
          height='24px'
          pointerEvents='none'
          _groupHover={isSelected ? undefined : { opacity: 1 }}
        >
          <CheckmarkIcon boxSize='16px' />
        </Box>
      </Box>
    );
  },
);
