import type { ProjectInfo } from '@playful/runtime';
import {
  UserOptions,
  getProjectById,
  getProjectBySlug,
  getProjectInfoByIdPath,
} from '@playful/workbench/api/projects';
import { useCallback } from 'react';
import { mutate as mutateByKey, preload, useSWRConfig } from 'swr';

/**
 * This allows projects to be loaded on demand, by username/slug or id, while doing
 * its best to load a project from cache before falling back to preloading it. The caches are
 * synced afterwards.
 *
 * NOTE: this is for individual projects and project keys. This doesn't sync back to any
 * projectStores or lists, or anything like that.
 */
export function useProjectFetcher() {
  const { cache } = useSWRConfig();

  const fetchBySlug = useCallback<
    (slug: string, opts: UserOptions) => Promise<ProjectInfo | undefined>
  >(async (slug: string, { id: userId, name: userName }: UserOptions) => {
    const userOptions = userName ? { name: userName } : userId ? { id: userId } : undefined;

    if (!userOptions) return;

    const fetchedInfo: ProjectInfo | undefined = await getProjectBySlug({
      slug,
      user: userOptions,
    });

    if (!fetchedInfo) return;

    // stuff into the project id cache, in case it still has an older copy
    mutateByKey(getProjectInfoByIdPath(fetchedInfo.id), fetchedInfo);

    return fetchedInfo;
  }, []);

  const fetchById = useCallback<(id: string) => Promise<ProjectInfo | undefined>>(
    async (id: string) => {
      // we use the projectId as the key, because it won't change (unlike the slug)
      const projectInfoKey = getProjectInfoByIdPath(id);
      const { data: cachedInfo } = cache.get(projectInfoKey) ?? {};

      if (cachedInfo) return cachedInfo;

      // if it's not cached yet, we should fetch it (in order of preference/availability):
      // - by projectId, with `preload`, in case we've preloaded it elsewhere before
      // - by slug, where we always fetch a fresh copy, as it can change
      const fetchedInfo: ProjectInfo | undefined = await preload(projectInfoKey, () =>
        getProjectById(id),
      );

      if (!fetchedInfo) return;

      // stuff into the project id cache, in case it still has an older copy
      mutateByKey(projectInfoKey, fetchedInfo);

      return fetchedInfo;
    },
    [cache],
  );

  return { fetchById, fetchBySlug };
}
