import {
  Box,
  BoxProps,
  Collapse,
  FormControl,
  FormLabelProps,
  forwardRef,
  useDisclosure,
} from '@chakra-ui/react';
import type { CustomDomain } from '@playful/api';
import { ChevronUpIcon, FormLabel, Switch, Text } from '@playful/design_system';
import React, { useEffect } from 'react';

import { CTAButton } from '../components/CallToAction/CTAButton';
import { useUserContext } from '../user/UserContext';
import { ChooseDomainAndPath } from './ChooseDomainAndPath';
import { ChooseSlug } from './ChooseSlug';
import { CurrentUrlDisplay } from './CurrentUrlDisplay';
import { DomainStatusBlock } from './DomainStatusBlock';
import { ProjectCustomThumbnail } from './ProjectCustomThumbnail';
import { ProjectPermissions } from './ProjectPermissions';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsCTAs } from './ProjectSettingsCTAs';
import { ProjectSettingsForm } from './ProjectSettingsForm';
import { ProjectSharingOptions } from './ProjectSharingOptions';
import { PublishButton } from './PublishButton';
import { SettingTitle } from './SettingsTitle';
import { SwitchField } from './SwitchField';
import { SwitchLabel } from './SwitchLabel';
import { useProject } from '../hooks/useProject';
import { isNil } from '@playful/utils';

export type ProjectSettingsLayoutProps = {
  children?: React.ReactNode;
  pendingDomainObject?: CustomDomain;
  currentDomainIsInvalid: boolean;
  isUpdatingUrl: boolean;
  isUrlDirty: boolean;
  isSwitchingToCustomDomain: boolean;
  onCancel: () => void;
  onSubmitNewUrl: () => void;
  CustomThumbnailInput?: React.ReactNode;
};

export const ProjectSettingsLayout = forwardRef((props: ProjectSettingsLayoutProps, ref) => {
  const { isOpen: isMoreSettingsOpen, onToggle: toggleMoreSettings } = useDisclosure();
  const {
    children,
    pendingDomainObject,
    currentDomainIsInvalid,
    onCancel,
    isUrlDirty,
    isUpdatingUrl,
    isSwitchingToCustomDomain,
    onSubmitNewUrl,
    CustomThumbnailInput,
    ...boxProps
  } = props;
  const { hasActiveSubscription } = useUserContext();
  const {
    isPublished,
    isUpdatedDomainCustom,
    canPublish,
    isEditingPublicUrl,
    setIsEditingPublicUrl,
  } = useProjectSettingsContext();

  return (
    <Box
      ref={ref}
      {...boxProps}
      px={6}
      pb={6}
      pt={4}
      height={'100%'}
      borderRadius={'lg'}
      maxHeight={'100%'}
      overflowY={'auto'}
    >
      <SettingTitle>{isPublished ? 'published!' : 'publish to the web'}</SettingTitle>
      {isPublished && isUpdatedDomainCustom && pendingDomainObject && (
        <DomainStatusBlock domain={pendingDomainObject} />
      )}

      {(!isPublished || isEditingPublicUrl) && (
        <>
          {!hasActiveSubscription && <ChooseSlug />}
          {hasActiveSubscription && <ChooseDomainAndPath />}
          {isPublished && isEditingPublicUrl && (
            <ProjectSettingsBox
              color={'gray.700'}
              borderTop={'none'}
              paddingTop={0}
              flexDirection={'column'}
            >
              <ProjectSettingsForm />
            </ProjectSettingsBox>
          )}
        </>
      )}

      {isPublished && !isEditingPublicUrl && (
        <CurrentUrlDisplay
          onEdit={() => {
            setIsEditingPublicUrl(true);
          }}
        />
      )}
      {isPublished && isEditingPublicUrl && (
        <>
          <Box display={'flex'} pt={0} mb={3} gap={4} justifyContent={'flex-end'}>
            <CTAButton colorScheme='gray' onClick={onCancel} isDisabled={currentDomainIsInvalid}>
              cancel
            </CTAButton>
            <CTAButton
              onClick={onSubmitNewUrl}
              flexGrow={1}
              isLoading={isUpdatingUrl}
              isDisabled={!isUrlDirty || !canPublish}
              title={isUrlDirty ? 'publish the new url' : 'no changes to publish'}
            >
              update
            </CTAButton>
          </Box>
          {!isSwitchingToCustomDomain ? (
            <Text size={'xs'} color={'gray.500'} mb={6}>
              Old links won&apos;t work after you update a URL
            </Text>
          ) : (
            <Box mb={3} />
          )}
        </>
      )}
      {isPublished && !isEditingPublicUrl && <ProjectSharingOptions />}
      {isPublished && !isEditingPublicUrl && <ProjectCustomThumbnail />}
      {isPublished && !isEditingPublicUrl && (
        <Box borderTop={'1px solid var(--play-colors-gray-50)'} mx={-6} px={6}>
          <SettingTitle mt={4}>settings</SettingTitle>
          <Box>
            <FormControl py={1}>
              <SearchEngineIndexing />
            </FormControl>
            <ProjectPermissions />
          </Box>
        </Box>
      )}
      {!isPublished && (
        <FormControl mb={4}>
          <SearchEngineIndexing />
        </FormControl>
      )}
      {!isPublished && (
        <Box
          cursor={'pointer'}
          justifyContent={'space-between'}
          alignItems={'center'}
          onClick={toggleMoreSettings}
          display={'flex'}
          color={'gray.600'}
          fontSize={'14px'}
          fontWeight={500}
        >
          <Text>more settings</Text>
          <ChevronUpIcon
            transform={`rotate(${isMoreSettingsOpen ? 180 : 0}deg)`}
            transition={'transform 0.3s ease-in-out'}
            w={4}
            h={4}
          />
        </Box>
      )}
      {!isPublished && (
        <Collapse in={isMoreSettingsOpen}>
          <ProjectSettingsBox color={'gray.500'} borderTop={'none'} flexDirection={'column'}>
            <ProjectSettingsForm />
          </ProjectSettingsBox>
        </Collapse>
      )}

      {!isEditingPublicUrl && <PublishButton isLoading={isUpdatingUrl} />}
      <ProjectSettingsCTAs />
    </Box>
  );
});

export const ProjectSettingsBox = ({
  children,
  ...boxProps
}: { children?: React.ReactNode } & BoxProps) => {
  return (
    <Box
      borderTop={'1px solid var(--play-colors-gray-50)'}
      mx={-6}
      px={6}
      py={4}
      display={'flex'}
      alignItems={'center'}
      gap={4}
      {...boxProps}
    >
      {children}
    </Box>
  );
};

export const ProjectSettingsFormLabel = ({
  children,
  ...props
}: { children: React.ReactNode } & FormLabelProps) => {
  return (
    <FormLabel fontSize={'sm'} fontWeight='normal' color={'gray.500'} {...props}>
      {children}
    </FormLabel>
  );
};

function SearchEngineIndexing() {
  const { projectInfo, onPageMetadataInputChange, pageMetadataInput } = useProjectSettingsContext();
  const { updateProjectMetadata, pageMetadata } = useProject({ id: projectInfo.id });

  // back-sync searchEngineIndex so we don't lose it on publish change where we use pageMetadataInput
  useEffect(() => {
    if (isNil(pageMetadata?.searchEngineIndex)) return;

    onPageMetadataInputChange((pageMetadataInput) => ({
      ...pageMetadataInput,
      searchEngineIndex: pageMetadata?.searchEngineIndex,
    }));
  }, [pageMetadata?.searchEngineIndex, onPageMetadataInputChange]);

  return (
    <SwitchField>
      <Box>
        <SwitchLabel
          m={0}
          htmlFor='no-index'
          tooltipLabel={'Allow search engines to index your site so it appears in search results'}
        >
          make site visible to search engines
        </SwitchLabel>
      </Box>
      <Switch
        id='no-index'
        size='md'
        name={'searchEngineIndex'}
        onChange={(e) =>
          updateProjectMetadata({
            pageMetadata: { ...pageMetadata, searchEngineIndex: e.target.checked },
          })
        }
        isChecked={pageMetadataInput?.searchEngineIndex}
      />
    </SwitchField>
  );
}
