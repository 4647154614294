import { subscriptionPaywalls } from '@playful/workbench/SubscriptionPaywalls';
import React from 'react';

import { CustomDomainCTA } from './CustomDomainCTA';
import { CustomDomainProCTA } from './CustomDomainProCTA';
import { GetHatchProCTA } from './GetHatchProCTA';
import { useProjectSettingsContext } from './ProjectSettingsContext';

export const ProjectSettingsCTAs = () => {
  const { isPublished, projectInfo, isEditingPublicUrl } = useProjectSettingsContext();

  // Use isn't a subscriber and has reached published project limit
  const showGetHatchProCTA = !isPublished && !subscriptionPaywalls.publishProject(projectInfo);

  // User isn't a subscriber and hasn't reached published project limit
  const showCustomDomainCTA =
    !showGetHatchProCTA &&
    (!isPublished || isEditingPublicUrl) &&
    !subscriptionPaywalls.customDomain();

  // User is a subscriber
  const showProCustomDomainCTA =
    (!isPublished || isEditingPublicUrl) && subscriptionPaywalls.hasActiveSubscription();

  return (
    <>
      {showGetHatchProCTA && <GetHatchProCTA />}
      {showCustomDomainCTA && <CustomDomainCTA />}
      {showProCustomDomainCTA && <CustomDomainProCTA />}
    </>
  );
};
