import { Box, Button } from '@playful/design_system';
import { subscriptionPaywalls } from '@playful/workbench/SubscriptionPaywalls';
import React from 'react';

import { CTAButton } from '../components/CallToAction/CTAButton';
import { useProjectSettingsContext } from './ProjectSettingsContext';

export function PublishButton({ isLoading }: { isLoading?: boolean }) {
  const { isPublished, onPublished, canPublish, projectInfo } = useProjectSettingsContext();

  const handleClick = () => {
    onPublished();
  };

  return (
    <Box mt={4}>
      {!isPublished && subscriptionPaywalls.publishProject(projectInfo) && (
        <CTAButton
          width={'100%'}
          onClick={handleClick}
          isDisabled={!canPublish}
          id={'publish-button'}
          isLoading={isLoading}
        >
          publish it
        </CTAButton>
      )}
      {isPublished && (
        <Button
          id={'unpublish-button'}
          width={'100%'}
          onClick={handleClick}
          size={'md'}
          height={'40px'}
          fontWeight={'normal'}
          isLoading={isLoading}
          backgroundColor={'red.500'}
          color={'white'}
        >
          unpublish
        </Button>
      )}
    </Box>
  );
}
