import { FormHelperText } from '@chakra-ui/react';
import {
  Box,
  Divider,
  ExternalLinkIcon,
  FormControl,
  Input,
  Link,
  ProBadgeIcon,
  Text,
  Textarea,
  Tooltip,
} from '@playful/design_system';
import { subscriptionPaywalls } from '@playful/workbench/SubscriptionPaywalls';
import { FEATURE_INTERNAL_TOOLS } from '@playful/api';

import React from 'react';

import { PasswordProtectSetting } from './PasswordProtectSetting';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsFormLabel } from './ProjectSettingsLayout';
import { useUserContext } from '../user/UserContext';
export function ProjectSettingsForm() {
  const {
    googleAnalyticsIdInputValue,
    onGoogleAnalyticsIdInputValue,
    googleAnalyticsErrMsg,
    customHeaderContentInputValue,
    onCustomHeaderContentInputValue,
    pageMetadataInput,
    onPageMetadataInputChange,
    projectInfo,
  } = useProjectSettingsContext();

  const { hasFlag } = useUserContext();

  const handleProClick = subscriptionPaywalls.addGoogleAnalytics()
    ? undefined
    : () => subscriptionPaywalls.openModal({ reason: 'add-google-analytics' });

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) {
    onPageMetadataInputChange({ ...pageMetadataInput, [e.target.name]: e.target.value });
  }

  return (
    <>
      <PasswordProtectSetting />
      <Divider variant='thin' />
      <Text fontSize='sm' fontWeight='medium' color='gray.600' alignSelf='flex-start'>
        SEO settings
      </Text>
      <FormControl>
        <ProjectSettingsFormLabel htmlFor={'site-title'}>site title</ProjectSettingsFormLabel>
        <Input
          id={'site-title'}
          name={'title'}
          placeholder={projectInfo?.title || 'add your meta title here'}
          variant='filled'
          value={pageMetadataInput?.title || ''}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl>
        <ProjectSettingsFormLabel htmlFor={'site-description'}>
          site description
        </ProjectSettingsFormLabel>
        <Textarea
          id={'site-description'}
          name={'description'}
          placeholder={'add your meta description here'}
          variant='filled'
          value={pageMetadataInput?.description || ''}
          onChange={handleChange}
          multiple
        />
      </FormControl>
      <FormControl mb={2} mt={0}>
        <ProjectSettingsFormLabel
          htmlFor={'google-analytics-id'}
          size={'md'}
          mb={1}
          fontWeight='normal'
          display={'flex'}
          gap={2}
          alignItems={'center'}
          onClick={handleProClick}
        >
          google analytics id
          {!subscriptionPaywalls.addGoogleAnalytics() && (
            <Tooltip
              hasArrow
              offset={[0, 12]}
              placement='top'
              label='upgrade to Hatch Pro to add your Google Analytics ID.'
            >
              <Box as='span' cursor='help'>
                <ProBadgeIcon h={5} w={5} data-testid={'watermark-pro-badge'} />
              </Box>
            </Tooltip>
          )}
          <Link
            href={'https://support.google.com/analytics/answer/9539598?hl=en'}
            isExternal={true}
            display={'flex'}
            alignItems={'center'}
            gap={1}
            marginLeft={'auto'}
          >
            find my id <ExternalLinkIcon size={'xs'} w={3} h={3} />
          </Link>
        </ProjectSettingsFormLabel>
        <Input
          id={'google-analytics-id'}
          placeholder={'G-XXXXXXXX'}
          variant='filled'
          value={googleAnalyticsIdInputValue}
          spellCheck={false}
          onChange={(e) => {
            if (subscriptionPaywalls.addGoogleAnalytics()) {
              onGoogleAnalyticsIdInputValue(e.target.value);
            }
          }}
          onClick={handleProClick}
          maxLength={30}
        />
        {!!googleAnalyticsErrMsg && (
          <FormHelperText color={'red'}>{googleAnalyticsErrMsg}</FormHelperText>
        )}
      </FormControl>
      {hasFlag(FEATURE_INTERNAL_TOOLS) && (
        <FormControl mb={2} mt={0}>
          <ProjectSettingsFormLabel
            htmlFor={'custom-header-content'}
            size={'md'}
            mb={1}
            fontWeight='normal'
            display={'flex'}
            gap={2}
            alignItems={'center'}
          >
            <Tooltip label='Add custom header content such as other analytics scripts to your site. Be sure to include opening and closing tags when needed, like: &lt;script&gt; and &lt;/script&gt; tags.'>
              custom header content
            </Tooltip>
          </ProjectSettingsFormLabel>
          <Textarea
            id={'custom-header-content'}
            variant='filled'
            value={customHeaderContentInputValue}
            spellCheck={false}
            onChange={(e) => {
              onCustomHeaderContentInputValue(e.target.value);
            }}
          />
        </FormControl>
      )}
    </>
  );
}
