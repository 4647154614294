import type { TextProps } from '@chakra-ui/react';
import { Text } from '@playful/design_system';
import React from 'react';

export function SettingTitle({ children, ...props }: TextProps) {
  return (
    <Text as={'h2'} fontSize={'lg'} fontWeight={'medium'} color={'gray.700'} py={2} {...props}>
      {children}
    </Text>
  );
}
