import type { UseDisclosureProps } from '@chakra-ui/react';
import type { BoxProps } from '@playful/design_system';
import type { ProjectInfo } from '@playful/runtime';
import { customEvent } from '@playful/telemetry';
import React, { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { AddDomainModal } from '../components/AddDomainModal';
import { useProject } from '../hooks/useProject';
import { useResource } from '../hooks/useResource';
import { useCustomDomains } from '../user/useCustomDomains';
import { useUserContext } from '../user/UserContext';
import { ProjectSettingsProvider } from './ProjectSettingsContext';
import { ProjectSettingsLayout } from './ProjectSettingsLayout';
import { useProjectEmbedCopy } from './useProjectEmbedCopy';
import { useProjectLinkCopy } from './useProjectLinkCopy';
import { useProjectSharing } from './useProjectSharing';

export type ProjectSettingsProps = UseDisclosureProps & {
  projectId?: string;
  isLoading?: boolean;
  onClose?: () => void;
  onSuccess?: (msg?: string | undefined) => void;
  onError?: (msg?: string | undefined) => void;
  onSlugUpdate?: (err: any, info?: ProjectInfo) => void;
  origin?: string; // for heap tracking; where did the user open project settings from?
} & Omit<BoxProps, 'onError' | 'children'>;

export const PROJECT_SETTINGS_MAX_WIDTH = '400px';

export const googleAnalyticsIdValdatorRegex = new RegExp(/^.+-.+$/);

function getCurrentDomain(info?: ProjectInfo) {
  if (!info) return { domain: '', isCustom: false, pathname: '' };
  try {
    if (!info.published || !info.publishedUrl) {
      const { host, pathname } = new URL(info.publicUrl);
      return { domain: host, isCustom: false, pathname: pathname?.slice(1) };
    }
    const { host, pathname } = new URL(info.publishedUrl);
    return { domain: host, isCustom: true, pathname: pathname?.slice(1) };
  } catch (e) {
    // if the url can't be parsed, log the error cause it's weird, but don't crash
    console.error(e);
    return { domain: '', isCustom: false, pathname: '' };
  }
}

export function getDefaultDomain(info?: ProjectInfo) {
  if (!info) return { host: '', pathname: '' };
  try {
    const { host, pathname } = new URL(info.publicUrl);
    return { host, pathname: pathname?.slice(1) };
  } catch (e) {
    console.error(e);
    return { host: '', pathname: '' };
  }
}

const createUrlFromDomainAndPath = (domain: string, path?: string) => {
  const url = new URL(`https://${domain}`);
  if (path) {
    url.pathname = path;
  }
  return url.href;
};

export function ProjectSettings(props: ProjectSettingsProps) {
  const {
    projectId,
    isLoading,
    onError,
    onClose,
    onSuccess,
    onSlugUpdate,
    isOpen,
    onOpen,
    origin, // heap analytics, where did the user open project settings from?
    ...boxProps
  } = props;
  const {
    projectInfo,
    setPublished,
    isPublished,
    slug,
    validateSlug,
    updateSlug,
    updateProjectPublishedUrl,
    updateProjectMetadata,
    isLoading: isProjectLoading,
    publicUrl = '',
    googleAnalyticsId = '',
    customHeaderContent = '',
    pageMetadata,
  } = useProject({
    id: projectId,
    onSlugUpdate,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  const { res: projectResource } = useResource(projectInfo?.project || '');
  const { onLinkCopy, isLinkCopied } = useProjectLinkCopy({
    projectId: projectId,
    containerRef,
    onError,
  });
  const {
    onCopyEmbed,
    isEmbedCopied,
    isLoading: isLoadingEmbed,
  } = useProjectEmbedCopy({
    projectId: projectId,
    containerRef,
    onError,
  });
  const { canShareNatively, onNativeShare, onSocialShare } = useProjectSharing({
    projectId,
    onShare: onClose,
    onError,
  });
  const {
    domain: currentDomain,
    isCustom: isPublishedDomainCustom,
    pathname,
  } = getCurrentDomain(projectInfo);
  const { host: defaultDomain } = getDefaultDomain(projectInfo);
  const { user } = useUserContext();
  const [isDomainModalOpen, setIsDomainModalOpen] = useState(false);
  const [updatedDomain, setUpdatedDomain] = useState(currentDomain);
  const [isEditingPublicUrl, setIsEditingPublicUrl] = useState(false);
  const [isUpdatingUrl, setIsUpdatingUrl] = useState(false);
  const [updatedSlug, setUpdatedSlug] = useState(slug);
  const [isSlugInputDirty, setIsSlugInputDirty] = useState(false);
  const [isInvalidSlug, setInvalidSlug] = useState(false);
  const [isDomainDirty, setIsDomainDirty] = useState(false);
  const {
    customDomains,
    isLoading: isCustomDomainsLoading,
    isValidating: isCustomDomainsValidating,
  } = useCustomDomains(user);
  const [path, setPath] = useState(pathname);
  const [pathInputValue, setPathInputValue] = useState(pathname);
  const [slugInputValue, setSlugInputValue] = useState(slug);

  const [googleAnalyticsIdInputValue, setGoogleAnalyticsIdInputValue] = useState(googleAnalyticsId);
  const isGoogleAnalyticsDirty = googleAnalyticsIdInputValue !== googleAnalyticsId;

  const [customHeaderContentInputValue, setCustomHeaderContentInputValue] =
    useState(customHeaderContent);
  const isCustomHeaderContentDirty = customHeaderContentInputValue !== customHeaderContent;

  const [pageMetadataInput, setPageMetadataInput] = useState(pageMetadata);
  const isPageMetadataDirty = JSON.stringify(pageMetadataInput) !== JSON.stringify(pageMetadata);

  const debouncedSlugCheck = useDebouncedCallback(checkSlug, 600);

  // Filter out domains that are just redirects
  const filteredCustomDomains = customDomains.filter((domain) => !domain.redirect);

  const currentDomainObject = customDomains.find((domain) => domain.name === currentDomain);
  const currentDomainIsInvalid = isPublishedDomainCustom && !currentDomainObject && !isDomainDirty;

  useEffect(() => {
    if (currentDomainIsInvalid && !isCustomDomainsLoading && !isCustomDomainsValidating) {
      setIsEditingPublicUrl(true);
      setUpdatedDomain(defaultDomain);
      setIsSlugInputDirty(true);
    }
  }, [
    currentDomainIsInvalid,
    defaultDomain,
    isCustomDomainsLoading,
    isCustomDomainsValidating,
    isEditingPublicUrl,
  ]);

  useEffect(() => {
    customEvent('projectsettings-open', {
      location: origin,
      projectId,
    });
  }, [origin, projectId]);

  if (!projectResource) return null;
  if (!projectInfo) return null;
  if (!projectId) return null;

  const pendingDomainObject = customDomains.find((domain) => domain.name === updatedDomain);
  let existingProject;
  if (pendingDomainObject) {
    existingProject = pendingDomainObject?.paths?.find(
      (p) => p.path === `/${path}` && p.projectId !== projectInfo.id,
    );
  }

  const isHatchDomainSelected = updatedDomain === defaultDomain;
  const wasDefaultDomain = currentDomain === defaultDomain;
  const isSwitchingToCustomDomain = wasDefaultDomain && !isHatchDomainSelected;

  const isUpdatedDomainCustom = !!pendingDomainObject;
  const isEmptySlug = !updatedSlug;

  const pathInvalid = !!existingProject;
  const pathErrMsg = pathInvalid ? 'url already points to a different project' : '';
  let domainErrMsg = '';
  if (currentDomainIsInvalid) {
    domainErrMsg = `domain doesn't exist (${currentDomain}) please choose a different one or add it`;
  }

  const emptyMsg = isEmptySlug && isSlugInputDirty && 'Please enter a URL.';
  // right now, we only check if the slug is taken, so we can just use this
  const takenMsg =
    isInvalidSlug && !isSlugInputDirty && `This URL is already taken. Please try something else.`;
  const slugErrMsg = emptyMsg || takenMsg;

  const isAnyLoading = isLoading || isProjectLoading;

  const isCustomDomainAndPathDirty = updatedDomain !== currentDomain || path !== pathname;
  const isSlugDirty = updatedSlug !== slug;
  const isTheURLSituationICareAboutDirty =
    isSlugDirty ||
    isCustomDomainAndPathDirty ||
    isGoogleAnalyticsDirty ||
    isCustomHeaderContentDirty ||
    isPageMetadataDirty;

  const googleAnalyticsError =
    googleAnalyticsIdInputValue &&
    isGoogleAnalyticsDirty &&
    !googleAnalyticsIdValdatorRegex.test(googleAnalyticsIdInputValue)
      ? 'Invalid Google Analytics ID'
      : '';

  const isSlugCurrentlyValid =
    !isHatchDomainSelected || (!isEmptySlug && !isInvalidSlug && !isSlugInputDirty);
  const isPathCurrentlyValid = isHatchDomainSelected || !pathInvalid;
  const canPublish = isSlugCurrentlyValid && isPathCurrentlyValid && !googleAnalyticsError;

  return (
    <ProjectSettingsProvider
      projectInfo={projectInfo}
      projectResource={projectResource}
      customDomains={filteredCustomDomains}
      currentDomain={currentDomain}
      slug={slug}
      path={path}
      pathInputValue={pathInputValue}
      slugInputValue={slugInputValue || ''}
      updatedSlug={updatedSlug}
      updatedDomain={updatedDomain}
      publicUrl={publicUrl}
      isLoading={isAnyLoading}
      isLinkCopied={isLinkCopied}
      isEmbedCopied={isEmbedCopied}
      isLoadingEmbed={isLoadingEmbed}
      isNativeShare={canShareNatively}
      isPublished={isPublished}
      isPublishedDomainCustom={isPublishedDomainCustom}
      isUpdatedDomainCustom={isUpdatedDomainCustom}
      isSlugInputDirty={isSlugInputDirty}
      onLinkCopy={onLinkCopy}
      onCopyEmbed={onCopyEmbed}
      onNativeShare={onNativeShare}
      onSocialShare={onSocialShare}
      onPublished={handlePublish}
      onDomainChange={handleDomainChange}
      onOpen={onOpen}
      onClose={onClose}
      onSlugChange={handleSlugChange}
      onPathChange={setPath}
      canPublish={canPublish}
      setPathInputValue={setPathInputValue}
      setSlugInputValue={setSlugInputValue}
      slugErrMsg={slugErrMsg}
      pathInvalid={pathInvalid}
      pathErrMsg={pathErrMsg}
      domainErrMsg={domainErrMsg}
      setIsEditingPublicUrl={setIsEditingPublicUrl}
      isEditingPublicUrl={isEditingPublicUrl}
      setIsDomainModalOpen={setIsDomainModalOpen}
      googleAnalyticsIdInputValue={googleAnalyticsIdInputValue}
      onGoogleAnalyticsIdInputValue={setGoogleAnalyticsIdInputValue}
      googleAnalyticsErrMsg={googleAnalyticsError}
      customHeaderContentInputValue={customHeaderContentInputValue}
      onCustomHeaderContentInputValue={setCustomHeaderContentInputValue}
      pageMetadataInput={pageMetadataInput}
      onPageMetadataInputChange={setPageMetadataInput}
    >
      <ProjectSettingsLayout
        ref={containerRef}
        currentDomainIsInvalid={currentDomainIsInvalid}
        isUpdatingUrl={isUpdatingUrl}
        isUrlDirty={isTheURLSituationICareAboutDirty}
        isSwitchingToCustomDomain={isSwitchingToCustomDomain}
        pendingDomainObject={pendingDomainObject}
        onCancel={handleCancel}
        onSubmitNewUrl={handleSubmitNewUrl}
        {...boxProps}
      />

      {isDomainModalOpen && (
        <AddDomainModal
          isOpen={isDomainModalOpen}
          onClose={() => {
            setIsDomainModalOpen(false);
          }}
        />
      )}
    </ProjectSettingsProvider>
  );

  function handleCancel() {
    setIsEditingPublicUrl(false);
    setUpdatedDomain(currentDomain);
    setPath(pathname);
    setPathInputValue(pathname);
    setUpdatedSlug(slug);
    setSlugInputValue(slug);
    setGoogleAnalyticsIdInputValue(googleAnalyticsId);
  }

  async function handleSubmitNewUrl() {
    if (isPublished) {
      setIsUpdatingUrl(true);
      if (updatedDomain === defaultDomain) {
        if (projectInfo?.publishedUrl) {
          await updateProjectPublishedUrl('');
        }
        await setSlug();
      } else {
        await setPublishedUrl();
      }

      if (isGoogleAnalyticsDirty || isPageMetadataDirty || isCustomHeaderContentDirty) {
        await updateProjectMetadata({
          googleAnalyticsId: googleAnalyticsIdInputValue,
          customHeaderContent: customHeaderContentInputValue,
          pageMetadata: pageMetadataInput,
        });
      }
      setIsEditingPublicUrl(false);
      setIsUpdatingUrl(false);
      onSuccess?.('your site has been updated');
    }
  }

  function handleDomainChange(newDomain: string) {
    setIsDomainDirty(true);
    const wasHatchDomain = updatedDomain === defaultDomain;
    if (newDomain === 'hatch-domain') {
      setUpdatedDomain(defaultDomain);
      setSlugInputValue(pathInputValue);
      handleSlugChange(pathInputValue);
    } else if (newDomain === 'new-domain') {
      setIsDomainModalOpen(true);
    } else {
      setUpdatedDomain(newDomain);
      setIsSlugInputDirty(false);
      setInvalidSlug(false);
      if (wasHatchDomain) {
        setPath(updatedSlug || path);
        setPathInputValue(updatedSlug || path);
      }
    }
  }

  function handleSlugChange(newSlug: string) {
    setIsSlugInputDirty(true);
    setUpdatedSlug(newSlug);
    debouncedSlugCheck(newSlug);
  }

  async function checkSlug(slugInputValue?: string) {
    if (!slugInputValue) return;

    const isValidSlug = await validateSlug?.(slugInputValue);
    setInvalidSlug(!isValidSlug);
    setIsSlugInputDirty(false);

    return isValidSlug;
  }

  async function setSlug() {
    // stop/cancel if any debounced slug check is still running
    debouncedSlugCheck.cancel();
    const isValid = await checkSlug(updatedSlug);

    if (isValid && updatedSlug) {
      await updateSlug(updatedSlug);
    }
  }

  async function setPublishedUrl() {
    if (updatedDomain === defaultDomain) {
      await updateProjectPublishedUrl('');
    } else {
      if (!pathInvalid) {
        const newPublicUrl = createUrlFromDomainAndPath(updatedDomain, path);
        await updateProjectPublishedUrl(newPublicUrl);
      }
    }
  }

  async function handlePublish() {
    setIsUpdatingUrl(true);
    if (!isPublished) {
      try {
        if (isUpdatedDomainCustom) {
          await setPublishedUrl();
        } else {
          await updateProjectPublishedUrl('');
          await setSlug();
        }
      } catch (err) {
        onError?.(`Error ${isPublished ? 'unpublishing' : 'publishing'} project.`);
        console.error(err);
        return;
      }
    }

    if (
      (isGoogleAnalyticsDirty || isPageMetadataDirty || isCustomHeaderContentDirty) &&
      (googleAnalyticsIdValdatorRegex.test(googleAnalyticsIdInputValue) ||
        !googleAnalyticsIdInputValue)
    ) {
      await updateProjectMetadata({
        googleAnalyticsId: googleAnalyticsIdInputValue,
        customHeaderContent: customHeaderContentInputValue,
        pageMetadata: pageMetadataInput,
      });
    }

    const [err, updatedInfo] = await setPublished?.(!isPublished);
    customEvent(`projectsettings-${isPublished ? 'unpublish' : 'publish'}-click`, {
      projectId,
      allowRemixing: updatedInfo?.permissions?.allowRemixing,
      isPublished,
      showLogo: updatedInfo?.permissions?.showLogo,
      showInGallery: updatedInfo?.permissions?.showInGallery,
    });
    setIsUpdatingUrl(false);
    if (err) return onError?.(`Error ${isPublished ? 'unpublishing' : 'publishing'} project.`);

    onSuccess?.(`your site has been ${isPublished ? 'unpublished' : 'published'}.`);
  }
}
