import { VStack } from '@chakra-ui/react';
import {
  Button,
  CheckmarkIcon,
  FacebookIcon,
  HStack,
  IconButton,
  LinkIcon,
  MoreVerticalIcon,
  TwitterIcon,
} from '@playful/design_system';
import React from 'react';

import { useProjectSettingsContext } from './ProjectSettingsContext';

export function ProjectSharingOptions() {
  const {
    isLoading,
    onLinkCopy,
    isLinkCopied,
    onCopyEmbed,
    isEmbedCopied,
    isLoadingEmbed,
    onSocialShare,
    isNativeShare,
    onNativeShare,
  } = useProjectSettingsContext();

  return (
    <VStack w='100%' spacing={3} pb={6} alignItems='flex-start'>
      <HStack spacing={3} w='100%'>
        <Button
          flexGrow={1}
          variant='secondary'
          onClick={onCopyEmbed}
          isLoading={isLoadingEmbed}
          isDisabled={isLoadingEmbed}
        >
          {!isEmbedCopied && 'copy embed'}
          {isEmbedCopied && 'embed copied!'}
        </Button>
        <Button
          isLoading={isLoading}
          isDisabled={isLoading}
          onClick={onLinkCopy}
          flexGrow={1}
          leftIcon={isLinkCopied ? <CheckmarkIcon /> : <LinkIcon />}
          colorScheme='yellow'
        >
          {!isLinkCopied && 'copy link'}
          {isLinkCopied && 'link copied!'}
        </Button>
      </HStack>
      <HStack spacing={1}>
        <IconButton
          variant={'ghost'}
          colorScheme='light'
          size='sm'
          onClick={() => onSocialShare('tw')}
          icon={<TwitterIcon />}
          aria-label='share on twitter'
        />
        <IconButton
          variant={'ghost'}
          colorScheme='light'
          size='sm'
          icon={<FacebookIcon />}
          onClick={() => onSocialShare('fb')}
          aria-label='share on facebook'
        />
        {isNativeShare && (
          <IconButton
            variant={'ghost'}
            colorScheme='light'
            size='sm'
            icon={<MoreVerticalIcon />}
            onClick={onNativeShare}
            aria-label='more share options'
          />
        )}
      </HStack>
    </VStack>
  );
}
