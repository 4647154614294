import { Box, Text } from '@playful/design_system';
import { ProjectInfo } from '@playful/runtime';
import { customEvent, triggerCustomEvent } from '@playful/telemetry';
import { fromPromise } from '@playful/utils';
import { getProjectInfoByIdPath, updateProjectById } from '@playful/workbench/api/projects';
import { PREVIEW_CUSTOM, Resource } from '@playful/workbench/project/resources';
import React, { useCallback } from 'react';
import { useSWRConfig } from 'swr';

import { ImageUpload } from '../components/ImageUpload';
import { useProject } from '../hooks/useProject';
import { useUserContext } from '../user/UserContext';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsBox } from './ProjectSettingsLayout';

export function ProjectCustomThumbnail() {
  const { projectResource, projectInfo, updatedDomain } = useProjectSettingsContext();
  const { setPublished, isPublished, hasUnpublishedChanges } = useProject({
    id: projectInfo.id,
  });

  const { user } = useUserContext();
  const { mutate: globalMutate } = useSWRConfig();
  const customThumbnail = projectResource.getData(PREVIEW_CUSTOM);
  const handleAfterUpload = useCallback(async () => {
    // If project is published, copy the custom preview into the published resource
    const currentThumbnail = projectResource.getData(PREVIEW_CUSTOM);
    const resourceId = projectInfo.publishedProject;
    if (resourceId) {
      const resource = await Resource.get(resourceId);
      if (currentThumbnail) {
        resource.cloneFrom(PREVIEW_CUSTOM, projectResource.id, PREVIEW_CUSTOM);
      } else {
        resource.removeData(PREVIEW_CUSTOM);
      }
    }

    // Update version number to force a rerender of image bc the preview URL doesn't change
    const [writeErr, updatedProjectInfo] = await fromPromise(
      updateProjectById(projectInfo.id, { version: projectInfo.version + 1 }),
    );
    if (!updatedProjectInfo || writeErr) return;

    customEvent('customthumbnail-success', {
      projectId: projectInfo.id,
      userId: user?.id,
    });

    // Update both caches
    globalMutate(
      `users/${user?.id}/projects`,
      (currentProjectInfos: any) => {
        const filteredProjectInfos = currentProjectInfos.filter(
          (pi: ProjectInfo) => pi.id !== updatedProjectInfo.id,
        );
        return [...filteredProjectInfos, updatedProjectInfo];
      },
      { revalidate: false },
    );
    globalMutate(getProjectInfoByIdPath(updatedProjectInfo.id), updatedProjectInfo, {
      revalidate: false,
    });

    // Update button will be enabled. If there weren't other unpublished changes, republish.
    if (isPublished && !hasUnpublishedChanges) {
      setPublished(true);
    }
  }, [
    hasUnpublishedChanges,
    isPublished,
    projectInfo.id,
    projectResource,
    setPublished,
    projectInfo.publishedProject,
    projectInfo.version,
    user?.id,
    globalMutate,
  ]);

  return (
    <ProjectSettingsBox>
      <Box
        display={'flex'}
        flexDirection={'column'}
        border={'1px solid var(--play-colors-gray-100)'}
        borderRadius={'8px'}
      >
        <ImageUpload
          resourceKey={PREVIEW_CUSTOM}
          property='customThumbnail'
          value={customThumbnail?.hash}
          projectResource={projectResource}
          afterUpload={handleAfterUpload}
          // TODO: This threads through ProjectCustomThumbnail => ImageUpload -> FileInput
          onOpen={triggerCustomEvent('customthumbnail-open')}
        />
        <Box padding={3}>
          <Text fontSize={'12px'} color={'gray.500'} fontWeight={400}>
            {updatedDomain}
          </Text>
          <Text fontSize={'12px'} color={'black'} fontWeight={500}>
            {projectInfo.title}
          </Text>
        </Box>
      </Box>
      <Box color='gray.500'>
        <Text fontWeight='bold' fontSize={'14px'}>
          thumbnail image
        </Text>
        <Text fontSize={'12px'}>shown in Hatch galleries and when sharing a link</Text>
        <br />
        <Text fontSize={'12px'}>recommended size: 1200x630</Text>
      </Box>
    </ProjectSettingsBox>
  );
}
