import { VStack } from '@chakra-ui/react';
import { ProBadgeIcon, Text } from '@playful/design_system';
import { subscriptionPaywalls } from '@playful/workbench/SubscriptionPaywalls';
import React from 'react';

import { CTAButton } from '../components/CallToAction/CTAButton';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsBox } from './ProjectSettingsLayout';

export const GetHatchProCTA = () => {
  const { onClose, onOpen } = useProjectSettingsContext();
  return (
    <ProjectSettingsBox mt={6} pt={6} pb={0}>
      <VStack>
        <CTAButton
          width='100%'
          onClick={() => {
            onClose?.();
            subscriptionPaywalls.openModal({
              onClose: () => onOpen?.(),
              handleClickFreePlan: () => onOpen?.(),
              reason: 'publish-cta',
              returnTo: ['project-settings-popover'],
            });
          }}
          id={'publish-button'}
          colorScheme='purple'
          leftIcon={<ProBadgeIcon fill='yellow.500' />}
        >
          get hatch pro
        </CTAButton>
        <Text fontSize='sm'>
          Free accounts are limited to 3 published projects. There are no limits with Hatch Pro!
          Upgrade to publish more and get a free domain name.
        </Text>
      </VStack>
    </ProjectSettingsBox>
  );
};
