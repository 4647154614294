import { Image, ImageProps } from '@chakra-ui/react';
import { ProjectInfo, ResourceUrlOptions, getResourceUrl } from '@playful/runtime';
import { PREVIEW } from '@playful/workbench/project/resources';
import React from 'react';

import logoUrl from '../assets/black.svg?url';
import { useProject } from '../hooks/useProject';
import { useUserContext } from '../user/UserContext';

type UseProjectPreviewOptions = {
  projectInfo?: ProjectInfo;
  showPublished?: boolean;
  previewTransform?: string;
};

export const useProjectPreview = ({
  projectInfo,
  showPublished = false,
  previewTransform = 'rs:fit:640:640',
}: UseProjectPreviewOptions) => {
  const projectVersion = projectInfo?.version;
  const { authToken } = useUserContext();
  const resourceId = showPublished ? projectInfo?.publishedProject : projectInfo?.project;
  const opts: ResourceUrlOptions = {
    transform: previewTransform,
    v: '' + projectVersion,
  };

  if (!showPublished) {
    // if we're showing a private project to the project's owner attach their auth token.
    // add it this was to avoid `auth=undefined` on public requests
    opts['auth'] = authToken;
  }

  const previewUrl = resourceId && getResourceUrl(resourceId, PREVIEW, '', opts);

  return {
    previewUrl: previewUrl ? previewUrl : undefined,
  };
};

export function PreviewImage(props: ImageProps) {
  return (
    <Image
      display='block'
      width='full'
      height='full'
      objectFit={'cover'}
      objectPosition={'top'}
      fallbackSrc={logoUrl}
      fallbackStrategy={'onError'}
      {...props}
      src={!!props.src ? props.src : logoUrl}
    />
  );
}

type ProjectPreviewProps = ImageProps &
  Partial<Pick<UseProjectPreviewOptions, 'showPublished' | 'previewTransform'>> & {
    projectId?: string;
  };

export function ProjectPreview({
  projectId,
  showPublished = false,
  previewTransform = 'rs:fit:640:640',
  ...props
}: ProjectPreviewProps) {
  const { projectInfo } = useProject({ id: projectId });
  const { previewUrl } = useProjectPreview({ projectInfo, showPublished, previewTransform });
  const title = projectInfo?.title;

  return <PreviewImage key={previewUrl} src={previewUrl} alt={title} {...props} />;
}
