import { useRef, useState } from 'react';

const MIN_SIZE = 10;
const MAX_SIZE = 100;

type ColorAlgorithms = 'sqrt' | 'simple' | 'dominant';

type UseColorProps = {
  algorithm?: ColorAlgorithms;
  step?: number;
  disabled?: boolean;
};

/**
 * Provides color insights for a provided image.
 * Wraps the bare minimum bits and algos from https://github.com/fast-average-color/fast-average-color
 */
export const useColor = (props?: UseColorProps) => {
  const imgRef = useRef<HTMLImageElement>(null);

  const [isComplete, setComplete] = useState(false);
  const [color, setColor] = useState<any>(null);

  const onLoad = () => {
    if (props?.disabled || isComplete) return;

    const element = imgRef.current;
    const complete = element?.complete;

    if (complete) {
      setColor(getColor(element, props));
      setComplete(true);
    }
  };

  return {
    onLoad,
    imgRef,
    ...color,
  };
};

type NaturalSize = {
  width: HTMLImageElement['naturalWidth'];
  height: HTMLImageElement['naturalHeight'];
};

function getColor(resource: HTMLImageElement, props?: UseColorProps) {
  const size = prepareSizeAndPosition({
    width: resource.naturalWidth,
    height: resource.naturalHeight,
  });

  const canvas = document.createElement('canvas');

  canvas.width = size.destWidth;
  canvas.height = size.destHeight;

  const ctx = canvas.getContext('2d');

  ctx?.clearRect(0, 0, size.destWidth, size.destHeight);

  ctx?.drawImage(
    resource,
    size.srcLeft,
    size.srcTop,
    size.srcWidth,
    size.srcHeight,
    0,
    0,
    size.destWidth,
    size.destHeight,
  );

  if (!size.destWidth || !size.destHeight) return null;

  const bitmapData = ctx?.getImageData(0, 0, size.destWidth, size.destHeight).data;

  if (!bitmapData) return null;

  const value = getColorFromBitmapData(bitmapData, props);

  return value && prepareResult(value);
}

const algorithms = {
  simple: simpleAlgorithm,
  sqrt: sqrtAlgorithm,
  dominant: dominantAlgorithm,
};

function getColorFromBitmapData(arr: Uint8ClampedArray, props: UseColorProps = {}) {
  const bytesPerPixel = 4;
  const { algorithm: algo = 'sqrt', step = 1 } = props;

  if (!arr) return;

  const arrLength = arr.length;
  const len = arrLength - (arrLength % bytesPerPixel);
  const algorithm = algorithms[algo];

  return algorithm(arr, len, {
    step: step * bytesPerPixel,
  });
}

function prepareSizeAndPosition(originalSize: NaturalSize) {
  const srcLeft = 0;
  const srcTop = 0;
  const srcWidth = originalSize.width;
  const srcHeight = originalSize.height;

  let destWidth = srcWidth;
  let destHeight = srcHeight;
  let factor;

  if (srcWidth > srcHeight) {
    factor = srcWidth / srcHeight;
    destWidth = MAX_SIZE;
    destHeight = Math.round(destWidth / factor);
  } else {
    factor = srcHeight / srcWidth;
    destHeight = MAX_SIZE;
    destWidth = Math.round(destHeight / factor);
  }

  if (
    destWidth > srcWidth ||
    destHeight > srcHeight ||
    destWidth < MIN_SIZE ||
    destHeight < MIN_SIZE
  ) {
    destWidth = srcWidth;
    destHeight = srcHeight;
  }

  return {
    srcLeft,
    srcTop,
    srcWidth,
    srcHeight,
    destWidth,
    destHeight,
  };
}

function prepareResult(value: number[]) {
  const rgb = value.slice(0, 3);
  const rgba = [...rgb, value[3] / 255];
  const isDarkColor = isDark(value);

  return {
    value,
    rgb: `rgb(${rgb.join(',')})`,
    rgba: `rgba(${rgba.join(',')})`,
    hex: arrayToHex(rgb),
    hexa: arrayToHex(value),
    isDark: isDarkColor,
    isLight: !isDarkColor,
  };
}

function toHex(num: number) {
  const str = num.toString(16);

  return str.length === 1 ? `0${str}` : str;
}

function arrayToHex(arr: number[]) {
  return `#${arr.map(toHex).join('')}`;
}

function isDark(color: number[]) {
  // http://www.w3.org/TR/AERT#color-contrast
  const result = (color[0] * 299 + color[1] * 587 + color[2] * 114) / 1000;

  return result < 128;
}

function dominantAlgorithm(arr: Uint8ClampedArray, len: number, options: { step: number }) {
  const colorHash = {};
  const divider = 24;
  const { step } = options;
  let max = [0, 0, 0, 0, 0];

  if (!arr) return;

  for (let i = 0; i < len; i += step) {
    const red = arr[i];
    const green = arr[i + 1];
    const blue = arr[i + 2];
    const alpha = arr[i + 3];

    const key = (Math.round(red / divider) +
      ',' +
      Math.round(green / divider) +
      ',' +
      Math.round(blue / divider)) as keyof typeof colorHash;

    if (colorHash[key]) {
      colorHash[key] = [
        colorHash[key][0] + red * alpha,
        colorHash[key][1] + green * alpha,
        colorHash[key][2] + blue * alpha,
        colorHash[key][3] + alpha,
        colorHash[key][4] + 1,
      ] as keyof typeof colorHash;
    } else {
      colorHash[key] = [
        red * alpha,
        green * alpha,
        blue * alpha,
        alpha,
        1,
      ] as keyof typeof colorHash;
    }

    if (max[4] < colorHash[key][4]) {
      max = colorHash[key];
    }
  }

  const redTotal = max[0];
  const greenTotal = max[1];
  const blueTotal = max[2];

  const alphaTotal = max[3];
  const count = max[4];

  return [
    Math.round(redTotal / alphaTotal),
    Math.round(greenTotal / alphaTotal),
    Math.round(blueTotal / alphaTotal),
    Math.round(alphaTotal / count),
  ];
}

function simpleAlgorithm(arr: Uint8ClampedArray, len: number, options: { step: number }) {
  let redTotal = 0;
  let greenTotal = 0;
  let blueTotal = 0;
  let alphaTotal = 0;
  let count = 0;

  if (!arr) return;

  const step = options.step;

  for (let i = 0; i < len; i += step) {
    const alpha = arr[i + 3];
    const red = arr[i] * alpha;
    const green = arr[i + 1] * alpha;
    const blue = arr[i + 2] * alpha;

    redTotal += red;
    greenTotal += green;
    blueTotal += blue;
    alphaTotal += alpha;

    count++;
  }

  return [
    Math.round(redTotal / alphaTotal),
    Math.round(greenTotal / alphaTotal),
    Math.round(blueTotal / alphaTotal),
    Math.round(alphaTotal / count),
  ];
}

function sqrtAlgorithm(arr: Uint8ClampedArray, len: number, options: { step: number }) {
  let redTotal = 0;
  let greenTotal = 0;
  let blueTotal = 0;
  let alphaTotal = 0;
  let count = 0;

  if (!arr) return;

  const step = options.step;

  for (let i = 0; i < len; i += step) {
    const red = arr[i];
    const green = arr[i + 1];
    const blue = arr[i + 2];
    const alpha = arr[i + 3];

    redTotal += red * red * alpha;
    greenTotal += green * green * alpha;
    blueTotal += blue * blue * alpha;
    alphaTotal += alpha;

    count++;
  }

  return [
    Math.round(Math.sqrt(redTotal / alphaTotal)),
    Math.round(Math.sqrt(greenTotal / alphaTotal)),
    Math.round(Math.sqrt(blueTotal / alphaTotal)),
    Math.round(alphaTotal / count),
  ];
}
