import { useIsSmallScreen } from '@playful/design_system';
import { customEvent } from '@playful/telemetry';
import { fromPromise } from '@playful/utils';

import { useProject } from '../hooks/useProject';

export function useProjectSharing({
  projectId,
  onShare,
  onError,
}: {
  projectId?: string;
  onShare?: (isNative: boolean) => void;
  onError?: (err: any) => void;
}) {
  const { projectInfo, setPublished, isLoading, publicUrl } = useProject({
    id: projectId,
  });

  const shareData = { url: publicUrl, title: 'Check out this project on Hatch' };
  const encodedText = 'Check out this project on Hatch!';
  const shareUrls = {
    fb: `https://www.facebook.com/sharer/sharer.php?u=${publicUrl}%2F&amp;src=sdkpreparse`,
    tw: `https://twitter.com/intent/tweet?text=${encodeURIComponent(encodedText)}%20${publicUrl}`,
  };
  const isSmScreen = useIsSmallScreen();
  const canShareNatively = isSmScreen && navigator.canShare?.(shareData);

  async function handleNativeSharing() {
    if (!canShareNatively) return;

    const [err] = await fromPromise(navigator.share?.(shareData));

    // err if the user cancels, so we don't need to notify them
    if (err) return;

    // According to docs (https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share)
    // this will not be reached if the user cancels the share dialog.
    // On mobile this happens in addition to a 'share', { service: 'copy-link' } event.
    customEvent('share', { service: 'native', url: publicUrl });

    onShare?.(true);
  }

  async function handleSocialShare(service: keyof typeof shareUrls) {
    const url = shareUrls[service];

    // we can't do this after a promise
    window.open(url, '_blank');

    customEvent('share', { service, url: publicUrl });

    if (!projectInfo) {
      const [err] = await setPublished(true);

      if (err) return onError?.(`your project couldn't be shared.`);
    }

    onShare?.(false);
  }

  return {
    isLoading,
    canShareNatively,
    onSocialShare: handleSocialShare,
    onNativeShare: handleNativeSharing,
  };
}
