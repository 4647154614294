import { VStack } from '@chakra-ui/react';
import { Box, InformationalUpgradeCTA, Link } from '@playful/design_system';
import { useHoverDomains } from '@playful/workbench/useHoverDomains';
import React from 'react';

import { useUserContext } from '../user/UserContext';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsBox } from './ProjectSettingsLayout';

export const CustomDomainProCTA = () => {
  const { setIsDomainModalOpen } = useProjectSettingsContext();
  const { hoverVoucherState } = useUserContext();
  const userHasClaimedFreeDomain = hoverVoucherState === 'redeemed';
  const { redirectToHover } = useHoverDomains({});
  return (
    <ProjectSettingsBox mt={6} pt={6} pb={0}>
      <VStack w='100%'>
        {!userHasClaimedFreeDomain && (
          <InformationalUpgradeCTA
            isProMember
            onClick={redirectToHover}
            heading='Claim your free domain!'
            body={
              <>
                As a Hatch Pro subscriber, you get to{' '}
                <Box as='span' textDecoration='underline'>
                  choose a free custom domain
                </Box>
              </>
            }
          />
        )}
        {userHasClaimedFreeDomain && (
          <Link
            w='100%'
            size='md'
            fontWeight={'medium'}
            fontSize={'14px'}
            onClick={redirectToHover}
          >
            purchase a custom domain
          </Link>
        )}
        <Link
          w='100%'
          size='md'
          fontWeight={'medium'}
          fontSize={'14px'}
          onClick={() => {
            setIsDomainModalOpen(true);
          }}
        >
          connect a domain you own
        </Link>
      </VStack>
    </ProjectSettingsBox>
  );
};
