import { FormHelperText } from '@chakra-ui/react';
import { FormControl, Input, Select } from '@playful/design_system';
import { slugify } from '@playful/utils';
import React from 'react';

import { ChooseSlug } from './ChooseSlug';
import { getDefaultDomain } from './ProjectSettings';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsFormLabel } from './ProjectSettingsLayout';

export const HATCH_DOMAIN_TOKEN = 'hatch-domain';

export function ChooseDomainAndPath() {
  const {
    customDomains,
    onDomainChange,
    projectInfo,
    updatedDomain,
    isUpdatedDomainCustom,
    path,
    onPathChange,
    pathErrMsg,
    domainErrMsg,
    pathInvalid,
    pathInputValue,
    setPathInputValue,
  } = useProjectSettingsContext();

  return (
    <>
      <FormControl>
        <ProjectSettingsFormLabel htmlFor={'select-domain'}>domain</ProjectSettingsFormLabel>
        <Select
          data-testid={'select-domain'}
          id={'select-domain'}
          variant='filled'
          onChange={handleDomainChange}
          value={updatedDomain}
        >
          <option value={HATCH_DOMAIN_TOKEN}>{getDefaultDomain(projectInfo).host}</option>
          {customDomains.map((domain) => (
            <option key={domain.name} value={domain.name}>
              {domain.name}
            </option>
          ))}
          <option disabled value={''}>
            _________________
          </option>
          <option value='new-domain'>connect a new domain</option>
        </Select>
        {domainErrMsg && <FormHelperText color={'red'}>{domainErrMsg}</FormHelperText>}
      </FormControl>
      {isUpdatedDomainCustom && (
        <FormControl isInvalid={pathInvalid} my={4}>
          <ProjectSettingsFormLabel htmlFor={'customize-domain'} mb={1}>
            customize url
          </ProjectSettingsFormLabel>
          <Input variant='filled' value={pathInputValue} onChange={handlePathChange} />
          <FormHelperText color={pathInvalid ? 'red' : 'gray.500'}>
            {pathInvalid ? pathErrMsg : `${updatedDomain}/${path}`}
          </FormHelperText>
        </FormControl>
      )}
      {!isUpdatedDomainCustom && <ChooseSlug label={'customize url'} />}
    </>
  );

  function handleDomainChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const newDomain = e.target.value;
    onDomainChange(newDomain);
  }

  function handlePathChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPathInputValue(event.target.value);
    onPathChange(slugify(event.target.value));
  }
}
