import { customEvent } from '@playful/telemetry';
import { fromPromise } from '@playful/utils';
import { writeClipboardTextAsync } from '@playful/frontend/utils/clipboard';
import { useState } from 'react';

import { useProject } from '../hooks/useProject';
import { useProjectSharing } from './useProjectSharing';

export function useProjectLinkCopy({
  projectId,
  containerRef,
  onCopied,
  onError,
}: {
  projectId?: string;
  containerRef?: React.RefObject<HTMLElement>;
  onCopied?: () => void;
  onError?: (err: any) => void;
}) {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const { publicUrl = '', isLoading: isProjectLoading } = useProject({
    id: projectId,
  });
  const { onNativeShare } = useProjectSharing({
    projectId,
    onError,
  });

  async function handleLinkCopy() {
    const [err] = await fromPromise(
      writeClipboardTextAsync(
        async () => {
          return publicUrl;
        },
        publicUrl,
        containerRef?.current,
      ),
    );

    if (err) {
      console.error(err);
      return onError?.('Error copying link.');
    }

    // We report this as a share even though we can't know if the user actually follows through
    // and shares the link somehow.
    customEvent('share', { service: 'copy-link', url: publicUrl });

    setIsLinkCopied(true);
    setTimeout(() => setIsLinkCopied(false), 3000);

    await onNativeShare();
    onCopied?.();
  }

  return {
    isLinkCopied,
    isLoading: isProjectLoading,
    onLinkCopy: handleLinkCopy,
  };
}
