import { FormControl } from '@chakra-ui/react';
import { Box, HStack, PasswordInput, ProBadgeIcon, Switch, Tooltip } from '@playful/design_system';
import { subscriptionPaywalls } from '@playful/workbench/SubscriptionPaywalls';
import React, { useEffect, useState } from 'react';

import { useProject, useProjectPermissions } from '../hooks/useProject';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { SwitchField } from './SwitchField';
import { SwitchLabel } from './SwitchLabel';

export const PasswordProtectSetting = () => {
  const { isLoading, projectInfo } = useProjectSettingsContext();
  const [hasPassword, setHasPassword] = useState(false);
  const [password, setPassword] = useState(projectInfo?.password || '');
  const { updatePassword } = useProject({ id: projectInfo.id });
  const { setShared, isShared } = useProjectPermissions({ id: projectInfo.id });

  useEffect(() => {
    setHasPassword(!!projectInfo?.password.length);
    setPassword(projectInfo?.password || '');
  }, [projectInfo?.password]);

  function handlePasswordProtectionChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (subscriptionPaywalls.addPasswordProtection()) {
      setHasPassword(e.target.checked);
      if (!e.target.checked) {
        updatePassword('');
      }
    } else {
      subscriptionPaywalls.openModal({
        reason: 'passwordprotect',
        returnTo: ['project-settings-popover'],
      });
    }
  }

  function handleUpdatePassword() {
    updatePassword(password);
    if (isShared) {
      setShared(false);
    }
  }

  return (
    <Box w='100%'>
      <SwitchField>
        <Box pr={4}>
          <SwitchLabel htmlFor='password-protect' tooltipLabel={'Add a password to your project.'}>
            password protected
          </SwitchLabel>
        </Box>
        <HStack spacing={2}>
          {!subscriptionPaywalls.addPasswordProtection() && (
            <Tooltip
              hasArrow
              offset={[0, 12]}
              placement='top'
              label='Upgrade to Pro to add a password to your project.'
            >
              <Box as='span' cursor='help'>
                <ProBadgeIcon h={5} w={5} data-testid={'password-protect-pro-badge'} />
              </Box>
            </Tooltip>
          )}
          <Switch
            aria-label='password protection'
            id='password-protect'
            size='md'
            isDisabled={isLoading}
            onChange={handlePasswordProtectionChange}
            isChecked={hasPassword}
          />
        </HStack>
      </SwitchField>
      {hasPassword && (
        <FormControl mt={1} isInvalid={password.length < 1}>
          <PasswordInput
            variant='filled'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={() => handleUpdatePassword()}
          />
        </FormControl>
      )}
    </Box>
  );
};
