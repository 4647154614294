import type { ProjectInfo, ProjectPageMetadata, ProjectPermissions, Tags } from '@playful/runtime';
import { customEvent } from '@playful/telemetry';
import { FromPromiseReturn, fromPromise } from '@playful/utils';
import {
  getProjectBySlug,
  getProjectInfoByIdPath,
  publishProject,
  setProjectSlug,
  unpublishProject,
  updateProjectById,
} from '@playful/workbench/api/projects';
import { useCallback } from 'react';
import useSWR from 'swr';

import { useUserContext } from '../user/UserContext';
import { useProjectFetcher } from './useProjectFetcher';
import { useUserProjects } from './useProjects';

function useProjectApi({ id, fallbackData }: { id?: string; fallbackData?: ProjectInfo }) {
  // we use the projectId as the key, because it won't change (unlike the slug)
  const key = id ? getProjectInfoByIdPath(id) : undefined;
  const { fetchById } = useProjectFetcher();
  const { user } = useUserContext();
  const { mutate: mutateProjectInfos, projectInfos } = useUserProjects(user.id, [], {
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });
  const updateProjectInfos = useCallback(
    async (updatedProjectInfo?: ProjectInfo) => {
      const idx = projectInfos.findIndex(
        (projectInfo) => projectInfo.id === updatedProjectInfo?.id,
      );
      if (idx > -1 && updatedProjectInfo) {
        const newProjectInfos = [...projectInfos];
        newProjectInfos[idx] = updatedProjectInfo;
        await mutateProjectInfos(newProjectInfos, {
          revalidate: false,
          populateCache: true,
          optimisticData: newProjectInfos,
        });
      }
    },
    [mutateProjectInfos, projectInfos],
  );

  const {
    data: projectInfo,
    mutate,
    isLoading,
    error,
    isValidating,
  } = useSWR(key, () => (id ? fetchById(id) : undefined), {
    revalidateOnFocus: false,
    fallbackData,
  });

  const updateProjectPermissionsMutation = useCallback(
    async (perms: Partial<ProjectPermissions>) => {
      if (!projectInfo) return;

      const updatedPerms = {
        ...projectInfo.permissions,
        ...perms,
      };

      const shouldRepublish =
        !projectInfo.published && (perms.allowRemixing || perms.showInGallery);

      return mutate(
        async () => {
          const updatedInfo = await updateProjectById(projectInfo.id, {
            permissions: updatedPerms,
          });

          const updatedWithPublished = shouldRepublish
            ? await publishProject(projectInfo.id)
            : updatedInfo;

          updateProjectInfos(updatedInfo);
          return updatedWithPublished;
        },
        {
          optimisticData: {
            ...projectInfo,
            permissions: updatedPerms,
          },
          populateCache: true,
          revalidate: false,
          rollbackOnError: true,
        },
      );
    },
    [mutate, projectInfo, updateProjectInfos],
  );

  const publishMutation = useCallback(
    () =>
      mutate(
        async () => {
          if (projectInfo) {
            const updatedInfo = await publishProject(projectInfo.id);
            updateProjectInfos(updatedInfo);
            return updatedInfo;
          }
          return undefined;
        },
        {
          populateCache: true,
          revalidate: false,
          rollbackOnError: true,
        },
      ),
    [mutate, projectInfo, updateProjectInfos],
  );

  const unpublishMutation = useCallback(
    () =>
      mutate(
        async () => {
          if (!projectInfo) return;

          const updatedWithUnpublish = await unpublishProject(projectInfo.id);

          // TODO: this should prob be server side...
          const updatedInfo = await updateProjectById(projectInfo.id, {
            permissions: {
              ...updatedWithUnpublish.permissions,
              showInGallery: false,
            },
          });

          updateProjectInfos(updatedInfo);
          return updatedInfo;
        },
        {
          populateCache: true,
          revalidate: false,
          rollbackOnError: true,
        },
      ),
    [mutate, projectInfo, updateProjectInfos],
  );

  const renameProjectMutation = useCallback(
    (title: string) =>
      mutate(() => projectInfo && updateProjectById(projectInfo.id, { title }), {
        populateCache: true,
        revalidate: false,
        rollbackOnError: true,
      }),
    [mutate, projectInfo],
  );

  const setProjectTagsMutation = useCallback(
    (tags: Tags) =>
      mutate(
        async () => {
          if (projectInfo) {
            const updatedInfo = await updateProjectById(projectInfo.id, { tags });
            updateProjectInfos(updatedInfo);
            return updatedInfo;
          }
          return undefined;
        },
        {
          populateCache: true,
          revalidate: false,
          rollbackOnError: true,
        },
      ),
    [mutate, projectInfo, updateProjectInfos],
  );

  const setProjectSlugMutation = useCallback(
    (slug: string) =>
      mutate(() => projectInfo && setProjectSlug(projectInfo.id, slug), {
        populateCache: true,
        revalidate: false,
        rollbackOnError: true,
      }),
    [mutate, projectInfo],
  );

  const updateProjectOwnerNameMutation = useCallback(
    (ownerName: string) =>
      mutate(() => projectInfo && updateProjectById(projectInfo.id, { ownerName }), {
        populateCache: true,
        revalidate: false,
        rollbackOnError: true,
      }),
    [mutate, projectInfo],
  );

  const updateProjectPasswordMutation = useCallback(
    (password: string) =>
      mutate(() => projectInfo && updateProjectById(projectInfo.id, { password }), {
        populateCache: true,
        revalidate: false,
        rollbackOnError: true,
      }),
    [mutate, projectInfo],
  );

  const updateProjectPublishedUrlMutation = useCallback(
    (publishedUrl: string) =>
      mutate(() => projectInfo && updateProjectById(projectInfo.id, { publishedUrl }), {
        optimisticData: projectInfo ? { ...projectInfo, publishedUrl } : undefined,
        populateCache: true,
        revalidate: true,
        rollbackOnError: true,
      }),
    [mutate, projectInfo],
  );

  const updatePageMetadataMutation = useCallback(
    ({
      googleAnalyticsId,
      customHeaderContent,
      pageMetadata,
    }: {
      googleAnalyticsId?: string;
      customHeaderContent?: string;
      pageMetadata?: ProjectPageMetadata;
    }) =>
      mutate(
        () =>
          projectInfo &&
          updateProjectById(projectInfo.id, {
            googleAnalyticsId,
            customHeaderContent,
            pageMetadata,
          }),
        {
          optimisticData: projectInfo ? { ...projectInfo, pageMetadata } : undefined,
          populateCache: true,
          revalidate: false,
          rollbackOnError: true,
        },
      ),
    [mutate, projectInfo],
  );

  return {
    isLoading: isLoading && !projectInfo,
    projectInfo,
    error,
    isValidating,
    updateProjectPermissions: updateProjectPermissionsMutation,
    renameProject: renameProjectMutation,
    publish: publishMutation,
    unpublish: unpublishMutation,
    updateSlug: setProjectSlugMutation,
    updateProjectOwnerName: updateProjectOwnerNameMutation,
    updateProjectPublishedUrl: updateProjectPublishedUrlMutation,
    updatePageMetadata: updatePageMetadataMutation,
    updateProjectPassword: updateProjectPasswordMutation,
    setProjectTags: setProjectTagsMutation,
  };
}

export type UseProjectOptions = {
  id?: string;
  onUnpublish?(err: any, info: ProjectInfo | undefined): void;
  onRename?(err: any, info: ProjectInfo | undefined): void;
  onSetTags?(err: any, info: ProjectInfo | undefined): void;
  onSetPublished?(err: any, info: ProjectInfo | undefined): void;
  onSlugUpdate?(err: any, info: ProjectInfo | undefined): void;
  onMetadataUpdate?(err: any, info: ProjectInfo | undefined): void;
  fallbackData?: ProjectInfo;
};

/**
 * Wanting to use this with username and slug?
 *
 * You probably don't, bc we don't want to cache a project under anything other than id,
 * as the slug can change, and we could end up accidentally caching multiple versions of a
 * project under multiple slugs, as well as its id, which is really confusing.
 *
 * Instead, try useProjectInfoFetcher `loadBySlug` fn in your component. It'll return an info
 * while also populating the `projects/${projectId}` cache with the returned info id, so you
 * can take that returned info and pass its id back into here (it'll then return the project
 * from the cache).
 */
export function useProject({
  onUnpublish,
  onRename,
  onSetPublished,
  onSetTags,
  onSlugUpdate,
  onMetadataUpdate,
  id,
  fallbackData,
}: UseProjectOptions) {
  const {
    isLoading,
    isValidating,
    projectInfo,
    renameProject,
    publish,
    unpublish,
    updateSlug,
    updateProjectOwnerName,
    updateProjectPassword,
    updateProjectPublishedUrl,
    setProjectTags,
    updatePageMetadata,
    error,
  } = useProjectApi({ id, fallbackData });
  const { published, publishedVersion = 0, version = 0, title } = projectInfo ?? {};
  const isPublished = !!published;

  const validateSlug = useCallback(
    async (newSlug: string) => {
      if (!newSlug || !projectInfo) return false;
      if (newSlug === projectInfo.slug) return true; // unchanged

      const [, exists] = await fromPromise(
        getProjectBySlug({ user: { name: projectInfo.ownerName }, slug: newSlug }),
      );

      return !exists;
    },
    [projectInfo],
  );

  return {
    isLoading,
    isValidating,
    projectInfo,
    error,
    published,
    publishedVersion,
    version,
    isPublished,
    title,
    publicUrl: projectInfo?.publishedUrl || projectInfo?.publicUrl,
    hasUnpublishedChanges: isPublished && version > publishedVersion,
    slug: projectInfo?.slug,
    googleAnalyticsId: projectInfo?.googleAnalyticsId,
    customHeaderContent: projectInfo?.customHeaderContent,
    pageMetadata: projectInfo?.pageMetadata,
    renameProject: async (name: string): FromPromiseReturn<ProjectInfo | undefined> => {
      const [err, updatedInfo] = await fromPromise(renameProject(name));
      onRename?.(err, updatedInfo);

      return [err, updatedInfo];
    },
    unpublish: async (): FromPromiseReturn<ProjectInfo | undefined> => {
      const [err, updatedInfo] = await fromPromise(unpublish());
      onUnpublish?.(err, updatedInfo);

      return [err, updatedInfo];
    },
    setPublished: async (shouldPublish: boolean): FromPromiseReturn<ProjectInfo | undefined> => {
      const [err, updatedInfo] = await fromPromise(shouldPublish ? publish() : unpublish());
      customEvent(!!updatedInfo?.published ? 'project-publish' : 'project-unpublish', {
        projectId: id,
        allowRemixing: updatedInfo?.permissions?.allowRemixing,
        isPublished,
        showLogo: updatedInfo?.permissions?.showLogo,
        showInGallery: updatedInfo?.permissions?.showInGallery,
        userId: updatedInfo?.owner,
        url: updatedInfo?.publishedUrl || updatedInfo?.publicUrl,
      });
      onSetPublished?.(err, updatedInfo);

      return [err, updatedInfo];
    },
    setTags: async (tags: Tags) => {
      const [err, updatedInfo] = await fromPromise(setProjectTags(tags));
      onSetTags?.(err, updatedInfo);

      return [err, updatedInfo];
    },
    updateSlug: async (slug: string): FromPromiseReturn<ProjectInfo | undefined> => {
      const [err, updatedInfo] = await fromPromise(updateSlug(slug));
      onSlugUpdate?.(err, updatedInfo);

      return [err, updatedInfo];
    },
    updatePassword: async (password: string) => {
      const [err, updatedInfo] = await fromPromise(updateProjectPassword(password));

      return [err, updatedInfo];
    },
    updateProjectOwnerName: async (
      ownerName: string,
    ): FromPromiseReturn<ProjectInfo | undefined> => {
      const [err, updatedInfo] = await fromPromise(updateProjectOwnerName(ownerName));

      return [err, updatedInfo];
    },
    updateProjectPublishedUrl: async (
      publishedUrl: string,
    ): FromPromiseReturn<ProjectInfo | undefined> => {
      const [err, updatedInfo] = await fromPromise(updateProjectPublishedUrl(publishedUrl));
      onSlugUpdate?.(err, updatedInfo);
      return [err, updatedInfo];
    },
    updateProjectMetadata: async (metadata: {
      googleAnalyticsId?: string;
      customHeaderContent?: string;
      pageMetadata?: {
        title?: string;
        description?: string;
        searchEngineIndex?: boolean;
      };
    }) => {
      const [err, updatedInfo] = await fromPromise(updatePageMetadata(metadata));
      onMetadataUpdate?.(err, updatedInfo);
      return [err, updatedInfo];
    },
    validateSlug,
  };
}

export type UseProjectPermissionsOptions = {
  id?: string;

  onSetShared?(err: any, info: ProjectInfo | undefined): void;
  onSetRemixable?(err: any, info: ProjectInfo | undefined): void;
  onSetBranding?(err: any, info: ProjectInfo | undefined): void;
  onSetLocked?(err: any, info: ProjectInfo | undefined): void;
};

export function useProjectPermissions({
  id,
  onSetShared,
  onSetRemixable,
  onSetBranding,
  onSetLocked,
}: UseProjectPermissionsOptions) {
  const { isLoading, projectInfo, updateProjectPermissions } = useProjectApi({
    id,
  });

  const projectSettingsEvent = (setting: string, value: string, projectId: string) => {
    customEvent('projectsettings-toggle', {
      setting,
      value,
      projectId,
    });
  };

  const disableShare = projectInfo?.password && projectInfo.password.length > 0;

  return {
    isLoading,
    setRemixable: async (val: boolean): FromPromiseReturn<ProjectInfo | undefined> => {
      const [err, info] = await fromPromise(updateProjectPermissions({ allowRemixing: val }));
      onSetRemixable?.(err, info);

      projectSettingsEvent('allowremixing', val.toString(), info?.id ?? '');

      return [err, info];
    },
    setShared: async (val: boolean): FromPromiseReturn<ProjectInfo | undefined> => {
      const [err, info] = await fromPromise(updateProjectPermissions({ showInGallery: val }));
      onSetShared?.(err, info);

      projectSettingsEvent('showinthecommunity', val.toString(), info?.id ?? '');

      return [err, info];
    },
    setBranding: async (val: boolean): FromPromiseReturn<ProjectInfo | undefined> => {
      const [err, info] = await fromPromise(updateProjectPermissions({ showLogo: val }));
      onSetBranding?.(err, info);

      // value is negated because we're tracking the opposite, don't show watermark/logo
      projectSettingsEvent('removewatermark', !val + '', info?.id ?? '');

      return [err, info];
    },

    setLocked: async (val: boolean): FromPromiseReturn<ProjectInfo | undefined> => {
      const [err, info] = await fromPromise(updateProjectPermissions({ locked: val }));
      onSetLocked?.(err, info);

      return [err, info];
    },
    isRemixable: projectInfo?.permissions.allowRemixing,
    isShared: projectInfo?.permissions.showInGallery,
    isLocked: projectInfo?.permissions.locked,
    hasBranding: projectInfo?.permissions.showLogo,
    disableShare: disableShare || false,
  };
}
