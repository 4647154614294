import { FlexProps, FormControl, FormControlProps } from '@chakra-ui/react';
import React from 'react';

export function SwitchField(props: FlexProps & FormControlProps) {
  return (
    <FormControl
      display='flex'
      py={1}
      justifyContent='space-between'
      alignItems='center'
      {...props}
    />
  );
}
