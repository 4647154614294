import { Box, InformationalUpgradeCTA } from '@playful/design_system';
import { subscriptionPaywalls } from '@playful/workbench/SubscriptionPaywalls';
import React from 'react';

import { useProjectSettingsContext } from './ProjectSettingsContext';
import { ProjectSettingsBox } from './ProjectSettingsLayout';

export function CustomDomainCTA() {
  const { onClose, onOpen } = useProjectSettingsContext();
  return (
    <ProjectSettingsBox mt={6} pt={6} pb={0}>
      <InformationalUpgradeCTA
        onClick={() => {
          onClose?.();
          subscriptionPaywalls.openModal({
            onClose: () => onOpen?.(),
            handleClickFreePlan: () => onOpen?.(),
            reason: 'customdomain',
            successModal: 'subscribe-success-domain-cta',
            returnTo: ['project-settings-popover'],
          });
        }}
        heading='Want a custom domain?'
        body={
          <>
            Claim a free custom domain or connect your own when you{' '}
            <Box textDecoration='underline'>upgrade to Hatch Pro</Box>
          </>
        }
      />
    </ProjectSettingsBox>
  );
}
