import type { BoxProps } from '@chakra-ui/react';
import { ModalFooter, useDisclosure } from '@chakra-ui/react';
import type { CustomDomain } from '@playful/api';
import { Box, Link } from '@playful/design_system';
import React from 'react';

import { CTAButton } from '../components/CallToAction/CTAButton';
import CustomDomainSetupDialog from '../user/Account/CustomDomainSetupDialog';

export function DomainStatusBlock({ domain }: { domain: CustomDomain }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (domain.status === 'PROVISIONING') {
    return (
      <InfoBlock backgroundColor={'yellow.100'}>
        Your domain is being verified. You can still publish, but your site won’t appear until the
        verification has completed.
        <p>
          <Link textDecoration={'underline'} onClick={onOpen}>
            view setup steps
          </Link>
        </p>
        <CustomDomainSetupDialog
          isOpen={isOpen}
          onClose={onClose}
          domain={domain}
          isPublishing={true}
          title={'connect your domain'}
          Footer={
            <ModalFooter pt={0} mt={-4}>
              <CTAButton onClick={onClose}>return to publishing</CTAButton>
            </ModalFooter>
          }
        />
      </InfoBlock>
    );
  }

  if (domain.status === 'FAILED') {
    return (
      <InfoBlock backgroundColor={'red.100'}>
        We have not been able to add your domain. Please click the link below to learn more and
        verify your domain information.
        <p>
          <Link textDecoration={'underline'} target={'_blank'} href={'/faqs'}>
            troubleshoot domain errors
          </Link>
        </p>
      </InfoBlock>
    );
  }

  return null;
}

function InfoBlock({ children, ...boxProps }: { children: React.ReactNode } & BoxProps) {
  return (
    <Box
      mt={1}
      mb={3}
      px={3}
      py={2}
      borderRadius={'8px'}
      background={'yellow.100'}
      fontSize={'12px'}
      {...boxProps}
    >
      {children}
    </Box>
  );
}
