import { FormControl, FormHelperText, FormHelperTextProps, InputProps } from '@chakra-ui/react';
import { Input } from '@playful/design_system';
import { slugify } from '@playful/utils';
import React, { ReactNode } from 'react';

export type SlugStates = {
  status: 'success' | 'error' | 'checking' | 'initial';
};

export function SlugHint({
  status,
  children,
  ...props
}: React.PropsWithChildren<SlugStates> & FormHelperTextProps) {
  return (
    <FormHelperText color={status === 'error' ? 'red' : 'gray.500'} {...props}>
      {children}
    </FormHelperText>
  );
}

export function SlugEditor({
  slug,
  onChange,
  status,
  slugHint,
  setInputValue,
  inputValue,
  ...inputProps
}: {
  onChange: (slug: string) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  slug?: string;
  slugHint?: ReactNode;
} & SlugStates &
  Omit<InputProps, 'onChange'>) {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    setInputValue(value);
    onChange?.(slugify(value));
  }

  return (
    <FormControl isInvalid={status === 'error'}>
      <Input
        onChange={handleChange}
        spellCheck={false}
        variant='filled'
        value={inputValue}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
          e.code === 'Enter' && e.currentTarget.blur()
        }
        {...inputProps}
      />
      <SlugHint status={status}>{slugHint}</SlugHint>
    </FormControl>
  );
}
