import { getOEmbed } from '@playful/api';
import { customEvent } from '@playful/telemetry';
import { fromPromise } from '@playful/utils';
import { writeClipboardTextAsync } from '@playful/frontend/utils/clipboard';
import { useCallback, useEffect, useState } from 'react';

import { useProject } from '../hooks/useProject';

export function useProjectEmbedCopy({
  projectId,
  containerRef,
  onCopied,
  onError,
}: {
  projectId?: string;
  containerRef?: React.RefObject<HTMLElement>;
  onCopied?: () => void;
  onError?: (err: any) => void;
}) {
  const [isEmbedCopied, setIsEmbedCopied] = useState(false);
  const { publicUrl = '', isPublished } = useProject({ id: projectId });

  const [embedCode, setEmbedCode] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);

  const getEmbedCode = useCallback(async () => {
    if (projectId) {
      const data = await getOEmbed({ projectId });
      setEmbedCode(data.html);
      setIsLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    if (isPublished) {
      getEmbedCode();
    }
  }, [getEmbedCode, isPublished]);

  async function handleEmbedCopy() {
    if (!embedCode) {
      return onError?.('Error copying embed html.');
    }

    const [err] = await fromPromise(
      writeClipboardTextAsync(
        async () => {
          return embedCode;
        },
        embedCode,
        containerRef?.current,
      ),
    );

    if (err) {
      console.error(err);
      return onError?.('Error copying embed html.');
    }

    customEvent('share', { service: 'copy-embed', url: publicUrl });

    setIsEmbedCopied(true);
    setTimeout(() => setIsEmbedCopied(false), 3000);
    onCopied?.();
  }

  return {
    isEmbedCopied,
    isLoading,
    onCopyEmbed: handleEmbedCopy,
  };
}
