import type { ResourceId, ResourceUrlOptions } from '@playful/runtime';
import { Resource } from '@playful/workbench/project/resources';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

export function useResource(initialResId: ResourceId): {
  res?: Resource;
} {
  const { data } = useSWR(`api/resources/${initialResId}`, async () => {
    const res = await Resource.get(initialResId);
    return res;
  });

  return { res: data };
}

export function useResourceDataUrl(
  initialResId: ResourceId,
  initialKey: string,
  initialOpts?: ResourceUrlOptions,
): {
  url?: string;
  setKey: (key: string) => void;
  setOpts: (opts: ResourceUrlOptions) => void;
} {
  const [key, setKey] = useState<string>(initialKey);
  const { res } = useResource(initialResId);
  const [opts, setOpts] = useState<ResourceUrlOptions | undefined>(initialOpts);
  const url = res?.getDataUrl(key, opts);
  return { url, setKey, setOpts };
}

export function useResourceDataUrlAuth(
  initialResId: ResourceId,
  initialKey: string,
  initialOpts?: ResourceUrlOptions,
): {
  url?: string;
  setResId: (resId: ResourceId) => void;
  setKey: (key: string) => void;
  setOpts: (opts: ResourceUrlOptions) => void;
} {
  const [resId, setResId] = useState<string>(initialResId);
  const [key, setKey] = useState<string>(initialKey);
  const [opts, setOpts] = useState<ResourceUrlOptions | undefined>(initialOpts);
  const [url, setUrl] = useState<string | undefined>();

  useEffect(() => {
    let mounted = true; // cancel setState call if unmounted
    const getUrl = async () => {
      try {
        const newUrl = await Resource.getDataUrlAuth(resId, key, opts);
        if (mounted) {
          setUrl(newUrl);
        }
      } catch (e) {
        console.error(e);
      }
    };
    getUrl();

    return () => {
      mounted = false;
    };
  }, [resId, key, opts, url]);

  return { url, setResId, setKey, setOpts };
}
