import type { PropertyChangeOption } from '@playful/runtime';
import { generateUUID, createImageThumbnail } from '@playful/utils';
import { addErrorToast } from '@playful/workbench/components/AppToasts';
import { MAX_RESOURCE_SIZE_IN_BYTES, Resource } from '@playful/workbench/project/resources';
import React, { useCallback, useState } from 'react';

import { FileInput } from './FileInput';

const defaultRules = {
  'image/gif': {
    maxSize: 1024 * 200,
  },
  'image/*': {
    resizeAfter: 1024 * 200,
    resizeWidth: 512,
  },
};

function getImageResourceKey(property: string, key?: string) {
  if (key) {
    return key;
  }
  return `${property}-upload-${generateUUID()}`;
}

type ImageUploadProps = {
  property: string;
  projectResource: Resource;
  resourceKey?: string;
  onValueChange?: (newValue: any, options?: PropertyChangeOption | undefined) => void;
  rules?:
    | Record<
        string,
        | {
            maxSize?: number | undefined;
          }
        | {
            resizeAfter: number;
            resizeWidth: number;
          }
      >
    | undefined;
  allowedMimeFilter?: string;
  maxSize?: number;
  value?: string;
  afterUpload?: () => void;
  onOpen?: () => void;
};

// NON-Editor version of the ImageUpload from ImageUploadEditor
// onValueChange can be null, but is provided for property editor support for image upload editor
export const ImageUpload = function ({
  onValueChange,
  value,
  resourceKey: rKey,
  property,
  rules = defaultRules,
  allowedMimeFilter = 'image/*',
  maxSize,
  projectResource,
  afterUpload,
  onOpen,
}: ImageUploadProps) {
  // If the property specifies a maxSize use it, otherwise fallback to the global max size we set
  const globalMaxSize = maxSize || MAX_RESOURCE_SIZE_IN_BYTES;
  const [isUploading, setIsUploading] = useState(false);
  /*
   fileKey is used to "reset" the input.
   This is useful when the FileInput accepts a file it thinks is valid, but then this PropertyEditor
   rejects and throws an error.
   */
  const [fileKey, setFileKey] = useState(0);
  const resourceKey = getImageResourceKey(property, rKey);

  const currentData =
    (!!value && (projectResource.getDataForHash(value) || projectResource.getData(resourceKey))) ||
    undefined;
  const opts = {
    transform: 'rs:fit:240:240',
  };
  const currentPreview = currentData && projectResource.getDataUrl(currentData.key, opts);
  const [preview, setPreview] = useState<File | Blob | undefined | string>(currentPreview);

  const handleAdd = useCallback(
    async (file: File) => {
      let blob: File | Blob = file;

      // Check to see if the mime type is allowed.
      const fallbackMimeFilterRule = allowedMimeFilter && new RegExp(allowedMimeFilter);
      if (fallbackMimeFilterRule && !fallbackMimeFilterRule.test(file.type)) {
        addErrorToast(`${file.type} is not allowed here`);
        setFileKey((i) => i + 1);
        return;
      }

      /*
        Additional rules can be specified for allowed mime types.
        image/gif might have different rules than image/png
      */
      const rule =
        rules &&
        Object.entries(rules).find(([filter]) => {
          return new RegExp(filter).test(file.type);
        })?.[1];

      // if maxSize is specified for this mime type and the size exceeds it, display error and bail
      if (rule && 'maxSize' in rule && rule.maxSize && file.size > rule.maxSize) {
        addErrorToast(
          `${file.name} is too large (${Math.round(
            file.size / 1024,
          )}kb). Please use an image smaller than ${rule.maxSize / 1024}kb`,
        );
        setFileKey((i) => i + 1);
        return;
      }

      // if resizeAfter is specified for this mime type resize the image to the `resizeWidth`. Maintain aspect ratio
      if (rule && 'resizeAfter' in rule && file.size > rule.resizeAfter && rule.resizeWidth) {
        blob = await createImageThumbnail(URL.createObjectURL(file), file.type, {
          width: rule.resizeWidth,
        });
      }

      // If the final blob (even if it was resized) is larger than the "maxSize"
      // throw error and bail.
      if (blob.size > globalMaxSize) {
        addErrorToast(
          `Image too large (${Math.round(blob.size / 1024)}kb). Please use an image smaller than ${
            globalMaxSize / 1024
          }kb`,
        );
        setFileKey((i) => i + 1);
        return;
      }

      // Set preview only after we've validated the file.
      setPreview(file);
      setIsUploading(true);
      projectResource
        .uploadDataBlob(resourceKey, blob)
        .then(({ resourceData: res }) => {
          if (res.hash) {
            onValueChange?.(res.hash, 'commit');
          }
          afterUpload?.();
        })
        .catch((e) => {
          addErrorToast('message' in e ? e.message : e);
          // clear the preview if something failed
          setPreview(undefined);
          setFileKey((i) => i + 1);
        });
      setIsUploading(false);
    },
    [
      onValueChange,
      allowedMimeFilter,
      globalMaxSize,
      projectResource,
      resourceKey,
      rules,
      afterUpload,
    ],
  );

  const handleReset = useCallback(() => {
    onValueChange?.(undefined, 'commit');
    setPreview(undefined);
    projectResource.removeData(resourceKey);
    afterUpload?.();
  }, [onValueChange, projectResource, resourceKey, afterUpload]);

  return (
    <>
      <FileInput
        key={`${property}-file-${fileKey}`}
        innerButton={() => <>Drag or upload an image</>}
        onReset={handleReset}
        allowedInputFileTypes={allowedMimeFilter || 'image/*'}
        onAdd={handleAdd}
        file={preview}
        inputId={`${property}-image-uploader`}
        isLoading={isUploading}
        onOpen={onOpen}
        BoxProps={{
          borderBottomRadius: '0px',
        }}
      />
    </>
  );
};
