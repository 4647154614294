import type { StringDictionary } from '@playful/utils';

type ShouldValidateOptions = {
  validateOnChange: boolean;
};

const defaultOptions: ShouldValidateOptions = {
  validateOnChange: false,
};

export function shouldValidate(
  values: StringDictionary,
  type: 'change' | 'blur' | 'submit',
  options?: ShouldValidateOptions,
): boolean {
  const { validateOnChange } = options || defaultOptions;

  if (type === 'change' && !validateOnChange) {
    return false;
  }
  if (type === 'blur') {
    for (const key in values) {
      if (values[key]) {
        return true;
      }
    }
    return false;
  }
  return true;
}

export type IdentifierValues = { identifier: string };

export function _validateIdentifier(identifier: string): boolean {
  const errors = validateIdentifier({ identifier }, 'submit');
  return !('identifier' in errors);
}

// Names must be a valid identifier. The rules: https://stackoverflow.com/a/9337047/707320.
export function validateIdentifier(
  values: IdentifierValues,
  type: 'change' | 'blur' | 'submit',
  options?: ShouldValidateOptions,
): Partial<IdentifierValues> & { other?: string } {
  const { identifier } = values;
  const errors: StringDictionary = {};

  if (shouldValidate(values, type, options)) {
    if (!identifier) {
      errors.identifier = 'Name is required.';
    } else if (
      [
        'instanceof',
        'typeof',
        'break',
        'do',
        'new',
        'var',
        'case',
        'else',
        'return',
        'void',
        'catch',
        'finally',
        'continue',
        'for',
        'switch',
        'while',
        'this',
        'with',
        'debugger',
        'function',
        'throw',
        'default',
        'if',
        'try',
        'delete',
        'in',
      ].includes(identifier)
    ) {
      errors.identifier = `"${identifier}" is a reserved name.`;
    } else if (!/^[_$a-zA-Z\xA0-\uFFFF][_$a-zA-Z0-9\xA0-\uFFFF]*$/i.test(identifier)) {
      errors.identifier = 'Names can only have letters, $, _, or emojis.';
    } else {
      // Try it just to be sure.
      try {
        // eslint-disable-next-line no-new-func
        Function(`var test = { ['${identifier}']: 1 }`);
      } catch (err) {
        errors.identifier = 'Invalid name.';
      }
    }
  }
  return errors;
}
