import { VStack } from '@chakra-ui/react';
import { getConfig } from '@playful/config';
import {
  Box,
  CloudDownloadIcon,
  IconButton,
  Link,
  Text,
  Tooltip,
  useIsSmallScreen,
} from '@playful/design_system';
import React, { useState } from 'react';

import { useProjectSettingsContext } from './ProjectSettingsContext';

export function CurrentUrlDisplay({ onEdit }: { onEdit: () => void }) {
  const {
    projectInfo: { id, title, pageMetadata, published },
    publicUrl,
  } = useProjectSettingsContext();

  const [isQrDownbloadShown, setIsQrDownloadShown] = useState(false);

  const isSmallScreen = useIsSmallScreen();

  const qrCodeUrl = `${getConfig().apiRoot}/projects/${id}/qrcode?published=${published}`;
  const qrCodeDownloadUrl = `${
    getConfig().apiRoot
  }/projects/${id}/qrcode?size=500&published=${published}`;

  return (
    <Box display={'flex'} alignItems={'center'} mb={4} mt={1} gap={3}>
      <Box
        width={'96px'}
        height={'96px'}
        minWidth={'96px'}
        minHeight={'96px'}
        padding={2}
        border={'1px solid var(--play-colors-gray-100)'}
        borderRadius={'8px'}
        boxShadow={'0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'}
        role={'group'}
        position={'relative'}
        overflow={'hidden'}
      >
        <img src={qrCodeUrl} />
        <Box
          _groupHover={{ opacity: 1 }}
          position={'absolute'}
          opacity={isQrDownbloadShown ? 1 : 0}
          transition={'opacity 0.2s ease-in-out'}
          display={'flex'}
          top={0}
          left={0}
          right={0}
          bottom={0}
          backgroundColor={'rgba(0, 0, 0, 0.5)'}
          justifyContent={'center'}
          alignItems={'center'}
          borderRadius={'8px'}
          cursor={'pointer'}
          color={'gray.700'}
          onClick={() => {
            if (isSmallScreen) {
              setIsQrDownloadShown(true);
            }
          }}
        >
          {(!isSmallScreen || isQrDownbloadShown) && (
            <Tooltip label={'download image of QR code'}>
              <IconButton
                as={'a'}
                pointerEvents={isQrDownbloadShown ? 'auto' : 'none'}
                _groupHover={{ pointerEvents: 'auto' }}
                backgroundColor={'gray.50'}
                download={`${pageMetadata?.title || title}-qr-code.png`}
                href={qrCodeDownloadUrl}
                aria-label={'download qr code'}
                icon={<CloudDownloadIcon />}
                onClick={(e) => {
                  setIsQrDownloadShown(false);
                  e.stopPropagation();
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Box>

      <VStack w='100%' alignItems='flex-start' spacing={2}>
        <Link
          color={'gray.700'}
          href={publicUrl}
          isExternal={true}
          textDecoration='underline'
          _hover={{ textDecoration: 'none' }}
        >
          <Text wordBreak={'break-word'}>{publicUrl}</Text>
        </Link>
        <Link onClick={onEdit} fontSize={'14px'} fontWeight={500} lineHeight={'20px'}>
          edit publish settings
        </Link>
      </VStack>
    </Box>
  );
}
