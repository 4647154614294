import { useCallback, useEffect, useRef } from 'react';

export type DragDetails = {
  canDrop: boolean;
};

export type useDragAndDropProps = {
  onDrag?: (event: DragEvent) => unknown;
  onDragIn?: (event: DragEvent, options: DragDetails) => unknown;
  onDragOut?: (event: DragEvent) => unknown;
  onDrop?: (event: DragEvent, options: DragDetails) => unknown;
  dragEffect?: 'copy' | 'move' | 'link' | 'none';
  allowedTypes?: Blob['type'][];
  disabled?: boolean;
};

/*
Handles drag and drop for a given HTMLElement reference
Adds and removes the appropriate event listeners

Recommended: Wrap any handlers passed into this hook with the useCallback (or useRef) hook to avoid reapplying the event listeners on every render.
*/
export const useDragAndDrop = <T extends HTMLElement>(props: useDragAndDropProps) => {
  const {
    onDrag,
    allowedTypes,
    onDragIn,
    onDragOut,
    onDrop,
    disabled,
    dragEffect = 'none',
  } = props;
  const elRef = useRef<T>(null);

  const memoizedDragIn = useCallback(
    function handleDragIn(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();

      if (disabled) return;

      const [fileToDrop] = event.dataTransfer?.items || [];

      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = dragEffect;
      }

      onDragIn?.(event, { canDrop: allowedTypes ? allowedTypes?.includes(fileToDrop.type) : true });
    },
    [onDragIn, dragEffect, allowedTypes, disabled],
  );

  const memoizedDragOut = useCallback(
    function handleDragOut(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();

      if (disabled) return;

      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = dragEffect;
      }

      onDragOut?.(event);
    },
    [onDragOut, dragEffect, disabled],
  );

  const memoizedDrag = useCallback(
    function handleDrag(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();

      if (disabled) return;

      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = dragEffect;
      }

      onDrag?.(event);
    },
    [onDrag, dragEffect, disabled],
  );

  const memoizedDrop = useCallback(
    function handleDrop(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();

      if (disabled) return;

      const [droppedFile] = event.dataTransfer?.files || [];

      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = dragEffect;
      }

      onDrop?.(event, { canDrop: allowedTypes ? allowedTypes?.includes(droppedFile.type) : true });
    },
    [onDrop, dragEffect, allowedTypes, disabled],
  );

  const memoOnDragOver = useCallback(
    function onDragOver(event: DragEvent) {
      event.preventDefault();

      if (disabled) return;

      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = dragEffect;
      }
    },
    [dragEffect, disabled],
  );

  useEffect(() => {
    const element = elRef.current;

    if (element) {
      element.addEventListener('dragenter', memoizedDragIn);
      element.addEventListener('dragleave', memoizedDragOut);
      element.addEventListener('dragover', memoizedDrag);
      element.addEventListener('drop', memoizedDrop);
      element.addEventListener('dragover', memoOnDragOver);
    }

    return () => {
      if (element) {
        element.removeEventListener('dragenter', memoizedDragIn);
        element.removeEventListener('dragleave', memoizedDragOut);
        element.removeEventListener('dragover', memoizedDrag);
        element.removeEventListener('drop', memoizedDrop);
        element.removeEventListener('dragover', memoOnDragOver);
      }
    };
  }, [memoizedDragIn, memoizedDrag, memoizedDragOut, memoizedDrop, memoOnDragOver]);

  return [elRef];
};
